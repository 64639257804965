import { Component } from '@angular/core';
import {Subscription} from "rxjs";
import {FormFilterService} from "../../../../../../../shared/services/form-filter.service";
import {environment} from "@environment/environment";

@Component({
  selector: 'app-select-object',
  templateUrl: './select-object.component.html',
  styleUrls: ['./select-object.component.scss']
})
export class SelectObjectComponent {

  constructor(public readonly formFilterService: FormFilterService) {
  }

  // Форма
  public filterForm = this.formFilterService.form;

  protected readonly environment = environment;
}
