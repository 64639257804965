import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DaData} from '../../shared/enums/da-data.enum';
import {map, switchMap} from 'rxjs/operators';

// Сервис DaData
@Injectable({
  providedIn: 'root'
})
export class DadataService {

  constructor(private readonly http: HttpClient) {
  }

  // Адрес сервера DaData
  private url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/';
  // Параметры запроса
  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: 'Token ' + environment.dadataKey
  });
  // Опции
  private options = {
    method: 'POST',
    mode: 'cors',
    body: {}
  };

  public suggestionAddress(query: string, part: string): Observable<any> {

    let url = '';
    let body = {};
    let filterFiasLevel = false;

    switch (part) {
      case DaData.ADDRESS:
        body = {
          from_bound: {value: 'region'},
          to_bound: {value: 'house'}
        };
        filterFiasLevel = true;
        url = this.url + 'address';
        break;
      case DaData.ADDRESS_FULL:
        body = {};
        url = this.url + 'address';
        break;
      case DaData.SURNAME:
        body = {
          parts: ['SURNAME']
        };
        url = this.url + 'fio';
        break;
      case DaData.NAME:
        body = {
          parts: ['NAME']
        };
        url = this.url + 'fio';
        break;
      case DaData.PATRONYMIC:
        body = {
          parts: ['PATRONYMIC']
        };
        url = this.url + 'fio';
        break;
      case DaData.FMS_UNIT:
        body = {
          parts: ['FMS_UNIT']
        };
        url = this.url + 'fms_unit';
        break;
      default:
        return new Observable<any>();
    }

    this.options.body = {
      query,
      count: 7,
      ...body
    };

    const headers = this.headers;
    const requestOptions = {headers};
    return this.http.post(url, this.options.body, requestOptions)
      .pipe(
        map((data: any) => data && data.suggestions
          .map((item: any, i: number) => {
          return {
            id: i,
            value: item.value,
            data: item?.data
          };
        })),
      );
  }

}
