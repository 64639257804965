<div *ngIf="!isTextarea" class="form-floating has-validation">
  <input [formControl]="searchControl"
         #inputElement
         [ngClass]="{
            'is-invalid': control.invalid && control.touched
           }"
         type="text"
         class="form-control"
         [placeholder]="placeholder"
         autocomplete="off"
         [readOnly]="isReadonly || isDropdown"
         [typeahead]="isReadonly ? undefined : typeaheadAsync ? asyncTypeaheadList : filteredList"
         [typeaheadAsync]="typeaheadAsync"
         [typeaheadOptionField]="typeaheadOptionField"
         [typeaheadScrollable]="typeaheadScrollable"
         [typeaheadOptionsInScrollableView]="typeaheadOptionsInScrollableView"
         [typeaheadOptionsLimit]="typeaheadOptionsLimit"
         [typeaheadMinLength]="typeaheadMinLength"
         [typeaheadSelectFirstItem]="true"
         [typeaheadIsFirstItemActive]="true"
         [attr.inputmode]="inputmode"
         [typeaheadWaitMs]="0"
         [optionsListTemplate]="isDropdown ? dropdownListTemplate : typeaheadAsync ? undefined : itemTemplate"
         [textMask]="{ mask: mask ? mask : false, guide: true, showMask: showMask }"
         (blur)="blurEvent($event)"
         (focus)="focusEvent($event)"
         (typeaheadOnSelect)="selectItem($event)"
         (keyup)="typeaheadAsync ? undefined : filterList($event)">
  <svg *ngIf="!isReadonly && (!isFocus || notReset)" class="icon-chevron" width="32" height="32" viewBox="0 0 32 32"
       fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.707 12.3266C22.5193 12.1175 22.2647 12 21.9993 12C21.7339 12 21.4794 12.1175 21.2917 12.3266L16.7016 17.4423C16.5113 17.6459 16.2577 17.7595 15.994 17.7595C15.7302 17.7595 15.4766 17.6459 15.2863 17.4423L10.6962 12.3266C10.5075 12.1234 10.2546 12.011 9.9922 12.0135C9.72977 12.0161 9.47873 12.1334 9.29316 12.3402C9.10758 12.547 9.00232 12.8268 9.00004 13.1193C8.99776 13.4118 9.09864 13.6935 9.28096 13.9039L13.8701 19.0196C14.1489 19.3304 14.4799 19.577 14.8443 19.7452C15.2086 19.9134 15.5991 20 15.9935 20C16.3878 20 16.7783 19.9134 17.1427 19.7452C17.507 19.577 17.838 19.3304 18.1169 19.0196L22.707 13.9039C22.8946 13.6947 23 13.4111 23 13.1153C23 12.8195 22.8946 12.5358 22.707 12.3266Z"
      fill="#E4E7EC"/>
  </svg>

  <a *ngIf="searchControl.value && isFocus && !isReadonly && !notReset" class="reset-btn" (click)="resetControl($event)">
    <svg data-qa="Cross" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
        <path d="m10 10-4.95 4.95m0-9.9L10 10 5.05 5.05ZM10 10l4.95 4.95L10 10Zm0 0 4.95-4.95L10 10Z"
              stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
        <path d="M79-22H-51a5 5 0 0 0-5 5v54a5 5 0 0 0 5 5H79a5 5 0 0 0 5-5v-54a5 5 0 0 0-5-5Z" stroke="currentColor"
              stroke-dasharray="10 5"></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="currentColor" d="M0 0h20v20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  </a>

  <label>{{label}}</label>

  <div *ngIf="isLoading" class="spinner-block spinner-grow spinner-grow-sm text-secondary" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>

<div *ngIf="isTextarea" class="form-floating">
  <textarea #textarea
            [formControl]="searchControl"
            [ngClass]="{
            'is-invalid': control.invalid && control.touched
           }"
            type="text"
            class="form-control"
            [placeholder]="placeholder"
            autocomplete="off"
            [readOnly]="isReadonly"
            [typeahead]="isReadonly ? undefined : typeaheadAsync ? asyncTypeaheadList : filteredList"
            [typeaheadAsync]="typeaheadAsync"
            [typeaheadOptionField]="typeaheadOptionField"
            [typeaheadScrollable]="typeaheadScrollable"
            [typeaheadOptionsInScrollableView]="typeaheadOptionsInScrollableView"
            [typeaheadOptionsLimit]="typeaheadOptionsLimit"
            [typeaheadMinLength]="typeaheadMinLength"
            [typeaheadSelectFirstItem]="true"
            [typeaheadIsFirstItemActive]="true"
            [adaptivePosition]="true"
            [typeaheadWaitMs]="0"
            [optionsListTemplate]="isDropdown ? dropdownListTemplate : typeaheadAsync ? undefined : itemTemplate"
            (blur)="blurEvent($event)"
            (focus)="focusEvent($event)"
            (input)="adjustTextareaHeight(textarea)"
            (typeaheadOnSelect)="selectItem($event)"
            (keyup)="typeaheadAsync ? undefined : filterList($event)"></textarea>
  <label>{{label}}{{getHeightControl()}}</label>

  <div *ngIf="isLoading" class="spinner-block spinner-grow spinner-grow-sm text-secondary" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>

<ng-template #itemTemplate let-result>
  <div *ngIf="filteredList.length" [style.height]="filteredList.length > 7 ? 7 * 46 + 'px' : 'auto'">
    <ul class="custom-list-group" [style.height]="filteredList.length > 7 ? 7 * 46 + 'px' : 'auto'">
      <li
        *ngFor="let match of filteredList; let i = index;"
        (click)="selectItem(match)" href="#">
        <a class="dropdown-item" [class.active]="match[typeaheadOptionField] === searchControl.value">
          {{ match[typeaheadOptionField] }}
        </a>
      </li>
      <li
        *ngFor="let match of asyncTypeaheadList | async; let i = index;"
        (click)="selectItem(match)" href="#">
        <a class="dropdown-item" [class.active]="match[typeaheadOptionField] === searchControl.value">
          {{ match[typeaheadOptionField] }}
        </a>
      </li>
    </ul>
  </div>
</ng-template>
<ng-template #dropdownListTemplate>
  <ul class="custom-list-group" [style.height]="typeaheadList.length > 7 ? 7 * 46 + 'px' : 'auto'">
    <li
      *ngFor="let match of typeaheadList; let i = index;"
      (click)="selectItem(match)" href="#">
      <a class="dropdown-item" [class.active]="match.value === searchControl.value">
        {{ match.value }}
      </a>
    </li>
  </ul>
</ng-template>
<app-form-field-error
  *ngIf="control?.touched"
  [controlErrors]="control.errors"
  [messages]="messages">
</app-form-field-error>
