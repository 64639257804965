// version: 1.1
import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { setTheme } from 'ngx-bootstrap/utils';
import { finalize } from 'rxjs/operators';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import {Router} from '@angular/router';

// Сервисы
import {AppService} from 'src/app/core/services/app.service';
import Swiper, {Autoplay} from "swiper";

@Component({
    selector: 'app-root',
    templateUrl: '../../../landings/lovit/app/app.component.html',
    styleUrls: ['../../../landings/lovit/app/app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('swiper', { static: true }) swiperRef: any;
  constructor(private readonly titleService: Title,
              private readonly elementRef: ElementRef,
              private readonly appService: AppService,
              public readonly router: Router) {
    setTheme('bs5');
  }

  // Заголовок сайта
  private title = 'СТРАХОВАНИЕ ИПОТЕКИ ОНЛАЙН';
  private faviconHref = 'assets/landingFiles/favicon-16x16.png';
  private favIcon: HTMLLinkElement | null = document.querySelector('#appIcon');

  // Индикатор загрузки
  public isLoading!: boolean;

  // События проверки интернет соединения
  private onlineEvent!: Observable<Event>;
  private offlineEvent!: Observable<Event>;

  // Подписка
  private subscriptions: Subscription[] = [];

  // Статус инетрнет соединения
  public connectionStatus!: string;

  get isShowBlock(): boolean {
    return !this.router.url.includes('form') && !this.router.url.includes('success');
  }

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
    console.log('Этот компонент заменили!');
    this.titleService.setTitle(this.title);
    if (this.favIcon != null) {
      this.favIcon.href = this.faviconHref;
    }
    this.checkNetworkStatus();
    this.isLoading = true;

    this.appService.loadApp()
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(() => {
        this.router.navigate(['/'], {queryParamsHandling: 'merge'});
      });
  }

  public ngAfterViewInit(): void {
    Swiper.use([Autoplay]);

    new Swiper(this.swiperRef.nativeElement, {
      slidesPerView: 2,
      speed: 8000,
      autoplay: {
        delay: 50,
        disableOnInteraction: false,
      },
      loop: false,
      breakpoints: {
        1140: {
          slidesPerView: 8,
          slidesPerColumn: 1,
          loop: true,
          allowTouchMove: true,
        },
        1000: {
          slidesPerView: 5,
          spaceBetween: 36,
          slidesPerColumn: 1,
          loop: true,
          allowTouchMove: true,
        },
        767: {
          slidesPerView: 2,
          spaceBetween: 17,
          slidesPerColumn: 1,
          allowTouchMove: true,
          loop: true,
        }
      }
    });
  }

  // // Уничтожение
  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  // --------------------------------------------------------------------------

  // Инициализация скрытого функционала
  public isAdmin(): boolean {
    const isAdmin = localStorage.getItem('isHideConfigInsapp');
    return isAdmin !== null && isAdmin !== '';
  }

  // Проверка инетрнет соединения
  private checkNetworkStatus(): void {
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      this.reloadPage();
      this.connectionStatus = 'online';
    }));

    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      this.connectionStatus = 'offline';
    }));
  }

  // Обновить страницу
  public reloadPage(): void {
    window.location.reload();
  }

  // Инициализация свайпера
  public scrollToForm(): void {
    const formElement = this.elementRef.nativeElement.querySelector('#mortgage-app');
    formElement.scrollIntoView({ behavior: 'smooth' });
  }

}
