<div class="selectObjectComponent row justify-content-between pb-0 pb-sm-3" [formGroup]="filterForm">
  <div class="mortgagePropertyTypes col-12 col-lg-12 col-xl-4">

    <div class="row d-flex align-items-center mb-3 mb-xl-0">
      <div class="col-12 col-md">
        <app-autocomplete
          formControlName="mortgagePropertyType"
          [typeaheadList]="formFilterService.mortgageObjects"
          [typeaheadOptionsLimit]="formFilterService.mortgageObjects.length"
          [notReset]="true"
          [isDropdown]="true"></app-autocomplete>
      </div>
    </div>

  </div>

  <div class="objectsInsurer col-12 col-md-12 col-lg-12 col-xl-8 text-start text-xl-end">
    <div class="row d-flex align-items-center justify-content-start justify-content-xl-end">
      <div *ngIf="environment.partnerSystemName === 'ozon'" class="col-12 col-md-auto col-xl-auto mb-2 mb-md-0">
        Застраховать
      </div>
      <div class="col-12 col-md-auto col-xl-auto mb-2 mb-md-0">
        <input formControlName="product" value="Property" type="radio" class="btn-check" id="object-insurer1" autocomplete="off" checked>
        <label class="btn btn-outline-primary btn-label-check w-100" for="object-insurer1">{{ environment.partnerSystemName === 'ozon' ? 'имущество' : 'Имущество' }}</label>
      </div>
      <div class="col-12 col-md-auto col-xl-auto mb-2 mb-md-0">
        <input formControlName="product" value="TitleAndProperty" type="radio" class="btn-check" id="object-insurer2" autocomplete="off" checked>
        <label class="btn btn-outline-primary btn-label-check w-100" for="object-insurer2">{{ environment.partnerSystemName === 'ozon' ? 'титул' : 'Титул' }}</label>
      </div>
      <div class="col-12 col-md-auto col-xl-auto mb-2 mb-md-0">
        <input formControlName="product" value="Life" type="radio" class="btn-check" id="object-insurer3" autocomplete="off">
        <label class="btn btn-outline-primary btn-label-check w-100" for="object-insurer3">{{ environment.partnerSystemName === 'ozon' ? 'жизнь и здоровье' : 'Жизнь и здоровье' }}</label>
      </div>
      <div class="col-12 col-md-auto col-xl-auto mb-2 mb-md-0">
        <input formControlName="product" value="LifeAndProperty" type="radio" class="btn-check" id="object-insurer4" autocomplete="off">
        <label class="btn btn-outline-primary btn-label-check w-100" for="object-insurer4">{{ environment.partnerSystemName === 'ozon' ? 'имущество + жизнь и здоровье' : 'Имущество + жизнь и здоровье' }}</label>
      </div>
    </div>

  </div>
</div>
