export function sizeWindow(): number {
    const modalContent = document.querySelector('.modal-content') as HTMLElement;
    return modalContent ? modalContent.offsetHeight : 0;
}
// Функция для изменения iframe окна
export function triggerIframe(): void {

    const dropdownElm = document.querySelector('.dropdown.open') as HTMLElement;
    setTimeout(() => {
        window.parent.postMessage({ frameHeight: document.body.scrollHeight }, '*');
    }, 50);
    if (dropdownElm) {
        window.parent.postMessage({ frameHeight: document.body.scrollHeight }, '*');
    }
}

// Функция, которая будет вызвана при клике вне блока .dropdown
export function handleClickOutsideDropdown(event: any): void {
    const targetElement = event.target;
    // Проверяем, что клик был не внутри элемента с классом .dropdown и не на самом элементе
    if (!targetElement.closest('.autocomplete')) {
        const dropdownElm = document.querySelector('.dropdown.open') as HTMLElement;
        if (dropdownElm) {
            // dropdownElm.remove();
            window.parent.postMessage({ frameHeight: document.body.scrollHeight }, '*');
        }
    }
}
