<div class="card mt-2 border-light h-auto shadow-sm rounded-5 offer" *ngIf="offer">
  <div class="card-body p-4">
    <span class="badge rounded-pill" style="{{ badge.style }}" *ngFor="let badge of offer.badges">
      {{badge.title}}
    </span>
    <div class="row align-items-center">
      <div class="col-12 col-sm-12 col-md-12 col-lg-3 mb-2 mb-lg-0">
        <div class="m-0 d-flex align-items-center">
          <img src="./assets/images/partner-logo/{{offer.insurerType}}-logo.svg"
               class="rounded d-inline-block" alt="{{offer.insurerName}}">
          <div>
            <div class="h6 m-0 p-2 pt-1 pb-0"><strong>{{ offer.insurerName }}</strong></div>
            <div class="m-0 p-2 pt-0 pb-0" *ngIf="offer.insurerRating">
              <rating [ngModel]="offer.insurerRating" [customTemplate]="t" [max]="5" [readonly]="true"></rating>
              <ng-template #t let-index="index" let-value="value">{{index < value ? '&#9733;' : '&#9733;'}}</ng-template>
              <div class="rating-value">{{offer.insurerRating}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-9">
        <div class="row offer-info">
          <div class="col-12 col-sm-6 col-lg-3">
            <div class="text-secondary">Ипотечный банк</div>
            <div class="fw-bold">
              {{mortgageService.selectedBank['bank']! || ''}}
            </div>
          </div>
          <div class="col-12 col-sm-6 col-lg-2">
            <div class="text-secondary">Цена</div>
            <div class="fw-bold">
              {{ getPreapprovePremiumAmount }} ₽
            </div>
          </div>
          <div class="col-12 col-sm-6 col-lg-3">
            <div class="text-secondary">Остаток по кредиту</div>
            <div class="fw-bold">
              {{ getCreditRemainAmount }} ₽
            </div>
          </div>
          <div class="col-12 col-sm-6 col-lg-3">
            <div class="text-secondary" *ngIf="bankSystemName !== 'ozon'">Страхование</div>
            <div class="text-secondary" *ngIf="bankSystemName === 'ozon'">Продукт</div>
            <div class="fw-bold">
              <span *ngIf="formFilterService.form.get('product')?.value.toLowerCase() === 'property'">
                Имущество
              </span>

              <span *ngIf="formFilterService.form.get('product')?.value.toLowerCase() === 'titleandproperty'">
                Титул
              </span>

              <span *ngIf="formFilterService.form.get('product')?.value.toLowerCase() === 'life'">
                Жизнь и здоровье
              </span>

              <span *ngIf="formFilterService.form.get('product')?.value.toLowerCase() === 'lifeandproperty'">
                Имущество + жизнь и здоровье
              </span>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-lg-1 mt-2 mt-lg-0">
            <button (click)="navigateToBack($event)" type="button"
                    class="btn btn-outline-secondary btn-edit d-none d-sm-block">
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_719_8126)">
                  <path
                    d="M28.0442 10.9566C27.4776 10.391 26.7098 10.0732 25.9092 10.0732C25.1086 10.0732 24.3407 10.391 23.7742 10.9566L10.2208 24.51C9.83272 24.8959 9.52498 25.355 9.31543 25.8606C9.10587 26.3663 8.99867 26.9084 9.00001 27.4558V29.1666C9.00001 29.3876 9.08781 29.5996 9.24409 29.7559C9.40037 29.9122 9.61233 30 9.83335 30H11.5442C12.0915 30.0015 12.6337 29.8945 13.1393 29.6851C13.645 29.4756 14.1041 29.168 14.49 28.78L28.0442 15.2258C28.6096 14.6593 28.9271 13.8916 28.9271 13.0912C28.9271 12.2908 28.6096 11.5231 28.0442 10.9566ZM13.3117 27.6016C12.8417 28.0685 12.2066 28.3314 11.5442 28.3333H10.6667V27.4558C10.6658 27.1274 10.7301 26.8021 10.8559 26.4987C10.9816 26.1953 11.1663 25.9198 11.3992 25.6883L21.685 15.4025L23.6017 17.3191L13.3117 27.6016ZM26.865 14.0475L24.7767 16.1366L22.86 14.2241L24.9492 12.135C25.075 12.0094 25.2244 11.9098 25.3887 11.842C25.553 11.7741 25.7291 11.7393 25.9069 11.7395C26.0847 11.7397 26.2607 11.7749 26.4249 11.8431C26.5891 11.9113 26.7382 12.0112 26.8638 12.137C26.9893 12.2629 27.0889 12.4122 27.1568 12.5766C27.2246 12.7409 27.2594 12.917 27.2592 13.0948C27.2591 13.2726 27.2238 13.4486 27.1556 13.6127C27.0874 13.7769 26.9875 13.9261 26.8617 14.0516L26.865 14.0475Z"
                    fill="#98A2B3"/>
                </g>
                <defs>
                  <clipPath id="clip0_719_8126">
                    <rect width="20" height="20" fill="white" transform="translate(9 10)"/>
                  </clipPath>
                </defs>
              </svg>
            </button>
            <button (click)="navigateToBack($event)" type="button"
                    class="btn btn-outline-secondary btn-edit mt-2 w-100 d-block d-sm-none">Редактировать
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>
