import {
  Component, ElementRef,
  forwardRef,
  HostListener,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  FormControlDirective,
  FormControlName,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
  NgControl
} from '@angular/forms';
import {Subscription} from 'rxjs';
import {FormService} from '../../services/form.service';
import {MASKS} from '../../constants/masks';
import {IMask} from '../../interface/masks.interface';
import {ValidationService} from '../../services/validation.service';
import {BsDatepickerConfig, BsDatepickerDirective, BsLocaleService} from "ngx-bootstrap/datepicker";
import {defineLocale, itLocale, ruLocale} from "ngx-bootstrap/chronos";
import moment from "moment-mini";

@Component({
  selector: 'app-form-date-field',
  templateUrl: './form-date-field.component.html',
  styleUrls: ['./form-date-field.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FormDateFieldComponent),
    multi: true,
  }],
})
export class FormDateFieldComponent implements ControlValueAccessor, OnInit, OnDestroy {

  // Заголовок
  @Input() label!: string;
  // Показываем календарь
  @Input() isShowCalendar: boolean = false;
  // Только для чтения
  @Input() isReadonly!: boolean;

  @ViewChild('inputDate', { static: false }) inputDate!: ElementRef;

  @ViewChild(BsDatepickerDirective, {static: false}) datepicker?: BsDatepickerDirective;

  @HostListener('window:scroll')
  onScrollEvent() {
    // this.datepicker?.hide();
  }

  // Настройки календаря
  public bsConfig?: Partial<BsDatepickerConfig>;

  // Локализавция календаря
  private locale = 'ru';

  // Контрол изменяемого input
  public inputControl: UntypedFormControl = new UntypedFormControl(null);
  public inputModel!: any;
  // Контрол
  public control!: UntypedFormControl;
  // Имя контрола
  public name!: string;
  // Подписка на контрол
  private subscription: Subscription = new Subscription();
  // Сообщения валидации
  public messages: { [key: string]: any } = {};
  // Флаг, идет загрузка списка
  public isLoading = false;
  // Паттерн - только числа
  public pattern = '[0-9]';
  // Маска для контролла даты
  public dateMask = [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/];
  // Максимальная дата для выбора в календаре
  public maxDate!: Date;
  // Минимальная дата для выбора в календаре
  public minDate!: Date;
  // Минимальная дата для выбора в календаре
  public minDateYear!: Date;

  // Вызовем когда значение изменится
  private onTouched: any = () => {
  };

  // Вызовем при любом действии пользователя с контроллом
  private onChange: any = () => {
  };

  constructor(@Inject(Injector) private injector: Injector,
              public readonly formService: FormService,
              private readonly validationService: ValidationService,
              private readonly localeService: BsLocaleService) {
    defineLocale('ru', ruLocale);
    localeService.use(this.locale);
    this.bsConfig = Object.assign({}, {
      showWeekNumbers: false
    });
  }

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
    MASKS.filter((item: IMask) => item.systemName === 'dateMask')
      .map((item) => this.dateMask = item.mask);

    const injectedControl = this.injector.get(NgControl);
    this.name = injectedControl.name + 'controlName';

    switch (injectedControl.constructor) {
      case FormControlName: {
        this.control = this.injector.get(FormGroupDirective).getControl(injectedControl as FormControlName);
        break;
      }
      default: {
        this.control = (injectedControl as FormControlDirective).form as UntypedFormControl;
        break;
      }
    }

    // Применяем параметры контрола
    if (injectedControl.name) {
      this.initPropertyControl(injectedControl.name.toString());
    }

    // Подписка на изменение контрола
    this.subscription.add(
      this.inputControl.valueChanges.subscribe((value) => {
        // Очистка значения контролла от пробелов и префикса
        const cleanValue = value && value.length > 0 ? value?.replace(/\s/g, '').replace(/\D/g, '') : value;
        this.control.setValue(cleanValue);
      })
    );

  }

  // Уничтожение
  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  // --------------------------------------------------------------------------

  // Применяем параметры контролла
  public initPropertyControl(injectedControlName: string): void {
    const propertyControl = this.formService.propertyControls[injectedControlName];
    if (propertyControl?.validation?.messages) {
      this.messages = propertyControl?.validation?.messages;
    }
    if (propertyControl?.label) {
      this.label = propertyControl.label;
    }

    if (propertyControl.maxDate === 'today' && this.bsConfig) {
      this.maxDate = moment().toDate();
      this.bsConfig.maxDate = this.maxDate;
    }

    if (propertyControl.maxDate && propertyControl.maxDate !== 'today' && this.bsConfig) {
      this.maxDate = moment().add(Number(propertyControl.maxDate), "days").toDate();
      this.bsConfig.maxDate = this.maxDate;
    }

    if (this.formService.policyStartDateMaxOffsetDaysDate && this.bsConfig && injectedControlName === 'mortgageStartDate') {
      this.maxDate = this.formService.policyStartDateMaxOffsetDaysDate;
      this.bsConfig.maxDate = this.maxDate;
    }

    if (propertyControl.minDate === 'today' && this.bsConfig) {
      this.minDate = moment().toDate();
      this.bsConfig.minDate = this.minDate;
    }

    if (propertyControl.minDate && propertyControl.minDate !== 'today' && this.bsConfig) {
      this.minDate = moment().add(Number(propertyControl.minDate), "days").toDate();
      this.bsConfig.minDate = this.minDate;
    }

    if (propertyControl.minDateYear && propertyControl.minDateYear !== 'today' && this.bsConfig) {
      this.minDateYear = moment().add(Number(propertyControl.minDateYear), "years").toDate();
      this.bsConfig.minDate = this.minDateYear;
    }
    if (propertyControl?.isReadonly) {
      this.isReadonly = propertyControl.isReadonly;
    }

    this.validationService.setControlValidation(propertyControl, this.control);

    return;
  }

  // Вызовет форма, если значение изменилось извне
  public writeValue(value: any): void {
    if (this.inputControl && this.inputControl.value !== value) {
      this.inputControl.setValue(value, {onlySelf: true, emitEvent: false});
      this.onChange(value);
      this.onTouched();

      const dateString = value;
      const date = moment(dateString, 'DD.MM.YYYY').toDate();
      // this.inputModel = date;
      if (this.inputDate?.nativeElement?.value) {
        this.inputDate.nativeElement.value = date;
      }
    }
  }

  // Сохраняем обратный вызов для изменений
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // Сохраняем обратный вызов для "касаний"
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Установка состояния disabled
  public setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.inputControl.disable() : this.inputControl.enable();
  }

  // Событие при выходи из сонктрола
  public blurControl(event: any): void {
    const dateString = event.target?.value;
    const date = moment(dateString, 'DD.MM.YYYY').toDate();
    // this.inputModel = date;
    if (this.inputDate?.nativeElement?.value) {
      this.inputDate.nativeElement.value = date;
    }
    this.control.markAsTouched();
  }

  // Фокус на контроле
  public focusDateControl(event: any): void {
  }

  public changeDate(event: any): void {
    const date = moment(event);
    const formattedDate = date.format("DD.MM.YYYY");
    this.inputControl.setValue(formattedDate);

  }
}
