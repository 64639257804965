import { Injectable } from '@angular/core';
import {environment} from "@environment/environment";

declare global {
    interface Window {
        ym: any;
    }
}

@Injectable({
    providedIn: 'root'
})
export abstract class YandexMetrikaService {

    constructor() {
        this.ym = window.ym;
    }

    // Яндекс метрика
    private readonly ym;

    // Отправка события в яндекс метрику
    public onYandexReachGoal(ymTarget: string, goalParams?: any): void {
      if (window.ym) {
        window.ym(environment.yandexMetrika, 'reachGoal', ymTarget);
        if (environment.yandexMetrikaPartner) {
          console.log(1111);
          window.ym(environment.yandexMetrikaPartner, 'reachGoal', ymTarget);
        }
      }
        // this.metrika.fireEvent(ymTarget);

    }
}
