<div class="card mt-2 mt-sm-4 border-light h-auto shadow-sm rounded-4">
  <div class="card-body p-2 p-sm-4">
    <div class="row card-form-block">
      <div class="col-auto card-form-block-icon">
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="48" height="48" rx="16" fill="#F2F4F7"/>
          <g clip-path="url(#clip0_154_4278)">
            <path d="M31 13H17C15.6744 13.0016 14.4036 13.5289 13.4662 14.4662C12.5289 15.4036 12.0016 16.6744 12 18V30C12.0016 31.3256 12.5289 32.5964 13.4662 33.5338C14.4036 34.4711 15.6744 34.9984 17 35H31C32.3256 34.9984 33.5964 34.4711 34.5338 33.5338C35.4711 32.5964 35.9984 31.3256 36 30V18C35.9984 16.6744 35.4711 15.4036 34.5338 14.4662C33.5964 13.5289 32.3256 13.0016 31 13ZM17 15H31C31.5988 15.0012 32.1835 15.1815 32.679 15.5178C33.1744 15.8541 33.5579 16.3309 33.78 16.887L26.122 24.546C25.5584 25.1073 24.7954 25.4225 24 25.4225C23.2046 25.4225 22.4416 25.1073 21.878 24.546L14.22 16.887C14.4421 16.3309 14.8256 15.8541 15.321 15.5178C15.8165 15.1815 16.4012 15.0012 17 15ZM31 33H17C16.2044 33 15.4413 32.6839 14.8787 32.1213C14.3161 31.5587 14 30.7956 14 30V19.5L20.464 25.96C21.4026 26.8963 22.6743 27.422 24 27.422C25.3257 27.422 26.5974 26.8963 27.536 25.96L34 19.5V30C34 30.7956 33.6839 31.5587 33.1213 32.1213C32.5587 32.6839 31.7956 33 31 33Z" fill="#626F80"/>
          </g>
          <defs>
            <clipPath id="clip0_154_4278">
              <rect width="24" height="24" fill="white" transform="translate(12 12)"/>
            </clipPath>
          </defs>
        </svg>
      </div>
      <div class="col-7 card-form-block-title p-0">
        <div class="fw-bold" [hidden]="formService.isContactFormComplete">Ваши контакты</div>
        <div class="text-secondary " [hidden]="formService.isContactFormComplete">Отправим полис на электронную почту после оплаты</div>
        <div class="fw-bold" [hidden]="!formService.isContactFormComplete">{{ phoneMask }}</div>
        <div class="text-secondary " [hidden]="!formService.isContactFormComplete">{{formContacts.value?.email}}</div>
      </div>
      <div class="col" [hidden]="!formService.isContactFormComplete">
        <div class="d-flex text-end justify-content-end">
          <button type="button" class="btn btn-outline-primary btn-lg d-none d-sm-block" (click)="formService.isContactFormComplete = !formService.isContactFormComplete">Редактировать</button>
          <button type="button" class="btn btn-outline-light p-2 btn-link d-block d-sm-none" (click)="formService.isContactFormComplete = !formService.isContactFormComplete">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_670_507)">
                <path d="M19.0442 0.956628C18.4776 0.390959 17.7098 0.0732422 16.9092 0.0732422C16.1086 0.0732422 15.3407 0.390959 14.7742 0.956628L1.22085 14.51C0.832722 14.8959 0.524981 15.355 0.315428 15.8606C0.105874 16.3663 -0.00133165 16.9084 1.24844e-05 17.4558V19.1666C1.24844e-05 19.3876 0.0878099 19.5996 0.24409 19.7559C0.40037 19.9122 0.612332 20 0.833346 20H2.54418C3.09148 20.0015 3.63365 19.8945 4.13931 19.6851C4.64496 19.4756 5.10406 19.168 5.49001 18.78L19.0442 5.22579C19.6096 4.65929 19.9271 3.8916 19.9271 3.09121C19.9271 2.29082 19.6096 1.52313 19.0442 0.956628ZM4.31168 17.6016C3.84168 18.0685 3.20665 18.3314 2.54418 18.3333H1.66668V17.4558C1.66584 17.1274 1.73014 16.8021 1.85588 16.4987C1.98161 16.1953 2.16628 15.9198 2.39918 15.6883L12.685 5.40246L14.6017 7.31913L4.31168 17.6016ZM17.865 4.04746L15.7767 6.13663L13.86 4.22413L15.9492 2.13496C16.075 2.00938 16.2244 1.90983 16.3887 1.84197C16.553 1.77411 16.7291 1.73929 16.9069 1.73948C17.0847 1.73967 17.2607 1.77488 17.4249 1.8431C17.5891 1.91131 17.7382 2.01119 17.8638 2.13704C17.9893 2.26289 18.0889 2.41225 18.1568 2.57657C18.2246 2.7409 18.2594 2.91698 18.2592 3.09477C18.2591 3.27255 18.2238 3.44856 18.1556 3.61274C18.0874 3.77692 17.9875 3.92605 17.8617 4.05163L17.865 4.04746Z" fill="#98A2B3"/>
              </g>
              <defs>
                <clipPath id="clip0_670_507">
                  <rect width="20" height="20" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
      </div>
    </div>

    <form [formGroup]="formContacts" novalidate [hidden]="formService.isContactFormComplete">

      <div class="row mt-4">
        <div class="col-12 col-md-4 col-xxl-4 mb-4">
          <app-form-text-field formControlName="email" [isReadonly]="isLoading"></app-form-text-field>
        </div>
        <div class="col-12 col-md-4 col-xxl-4 mb-4">
          <app-form-text-field formControlName="phone" [isReadonly]="isLoading"></app-form-text-field>
        </div>
      </div>

      <div class="row text-end justify-content-end">
        <div class="col text-end" *ngIf="!isApproveOffersComplete">
          <button type="button" class="btn btn-primary btn-lg" (click)="onSubmit(false)">Продолжить</button>
        </div>
        <div class="col-auto text-end" *ngIf="isApproveOffersComplete">
          <button type="button" class="btn btn-outline-secondary btn-lg" (click)="formService.isContactFormComplete = !formService.isContactFormComplete">Отмена</button>
        </div>
        <div class="col-auto text-end" *ngIf="isApproveOffersComplete">
          <button type="button" class="btn btn-primary btn-lg" (click)="onSubmit(true)">Сохранить</button>
        </div>
      </div>

    </form>

  </div>
</div>
