import { NgModule } from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {
    path: 'mortgage', loadChildren: () => import('./modules/mortgage/mortgage.module').then(m => m.MortgageModule),
    canActivate: []
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    preloadingStrategy: PreloadAllModules,
    useHash: false
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
