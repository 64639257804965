<div class="card mt-2 mt-sm-4 border-light h-auto shadow-sm rounded-4">
  <div class="card-body p-2 p-sm-4">
    <div class="row card-form-block">
      <div class="col-auto card-form-block-icon">
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="48" height="48" rx="16" fill="#F2F4F7"/>
          <g clip-path="url(#clip0_494_3704)">
            <path d="M31 14H30V13C30 12.7348 29.8946 12.4804 29.7071 12.2929C29.5196 12.1054 29.2652 12 29 12C28.7348 12 28.4804 12.1054 28.2929 12.2929C28.1054 12.4804 28 12.7348 28 13V14H20V13C20 12.7348 19.8946 12.4804 19.7071 12.2929C19.5196 12.1054 19.2652 12 19 12C18.7348 12 18.4804 12.1054 18.2929 12.2929C18.1054 12.4804 18 12.7348 18 13V14H17C15.6744 14.0016 14.4036 14.5289 13.4662 15.4662C12.5289 16.4036 12.0016 17.6744 12 19L12 31C12.0016 32.3256 12.5289 33.5964 13.4662 34.5338C14.4036 35.4711 15.6744 35.9984 17 36H31C32.3256 35.9984 33.5964 35.4711 34.5338 34.5338C35.4711 33.5964 35.9984 32.3256 36 31V19C35.9984 17.6744 35.4711 16.4036 34.5338 15.4662C33.5964 14.5289 32.3256 14.0016 31 14ZM14 19C14 18.2044 14.3161 17.4413 14.8787 16.8787C15.4413 16.3161 16.2044 16 17 16H31C31.7956 16 32.5587 16.3161 33.1213 16.8787C33.6839 17.4413 34 18.2044 34 19V20H14V19ZM31 34H17C16.2044 34 15.4413 33.6839 14.8787 33.1213C14.3161 32.5587 14 31.7956 14 31V22H34V31C34 31.7956 33.6839 32.5587 33.1213 33.1213C32.5587 33.6839 31.7956 34 31 34Z" fill="#626F80"/>
            <path d="M24 28.5C24.8284 28.5 25.5 27.8284 25.5 27C25.5 26.1716 24.8284 25.5 24 25.5C23.1716 25.5 22.5 26.1716 22.5 27C22.5 27.8284 23.1716 28.5 24 28.5Z" fill="#626F80"/>
            <path d="M19 28.5C19.8284 28.5 20.5 27.8284 20.5 27C20.5 26.1716 19.8284 25.5 19 25.5C18.1716 25.5 17.5 26.1716 17.5 27C17.5 27.8284 18.1716 28.5 19 28.5Z" fill="#626F80"/>
            <path d="M29 28.5C29.8284 28.5 30.5 27.8284 30.5 27C30.5 26.1716 29.8284 25.5 29 25.5C28.1716 25.5 27.5 26.1716 27.5 27C27.5 27.8284 28.1716 28.5 29 28.5Z" fill="#626F80"/>
          </g>
          <defs>
            <clipPath id="clip0_494_3704">
              <rect width="24" height="24" fill="white" transform="translate(12 12)"/>
            </clipPath>
          </defs>
        </svg>
      </div>
      <div class="col-7 card-form-block-title p-0">
        <div class="fw-bold" [hidden]="formService.isPolicyFormComplete">Срок действия полиса</div>
        <div class="text-secondary " [hidden]="formService.isPolicyFormComplete">Укажите дату начала страхования</div>
        <div class="fw-bold" [hidden]="!formService.isPolicyFormComplete">Срок действия полиса</div>
        <div class="text-secondary " [hidden]="!formService.isPolicyFormComplete">Укажите дату начала страхования</div>
      </div>
      <div class="col" *ngIf="!isPolicyFormDisabled" [hidden]="!formService.isPolicyFormComplete">
        <div class="d-flex text-end justify-content-end">
          <button type="button" class="btn btn-outline-primary btn-lg d-none d-sm-block" (click)="formService.isPolicyFormComplete = !formService.isPolicyFormComplete">Редактировать</button>
          <button type="button" class="btn btn-outline-light p-2 btn-link d-block d-sm-none" (click)="formService.isPolicyFormComplete = !formService.isPolicyFormComplete">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_670_507)">
                <path d="M19.0442 0.956628C18.4776 0.390959 17.7098 0.0732422 16.9092 0.0732422C16.1086 0.0732422 15.3407 0.390959 14.7742 0.956628L1.22085 14.51C0.832722 14.8959 0.524981 15.355 0.315428 15.8606C0.105874 16.3663 -0.00133165 16.9084 1.24844e-05 17.4558V19.1666C1.24844e-05 19.3876 0.0878099 19.5996 0.24409 19.7559C0.40037 19.9122 0.612332 20 0.833346 20H2.54418C3.09148 20.0015 3.63365 19.8945 4.13931 19.6851C4.64496 19.4756 5.10406 19.168 5.49001 18.78L19.0442 5.22579C19.6096 4.65929 19.9271 3.8916 19.9271 3.09121C19.9271 2.29082 19.6096 1.52313 19.0442 0.956628ZM4.31168 17.6016C3.84168 18.0685 3.20665 18.3314 2.54418 18.3333H1.66668V17.4558C1.66584 17.1274 1.73014 16.8021 1.85588 16.4987C1.98161 16.1953 2.16628 15.9198 2.39918 15.6883L12.685 5.40246L14.6017 7.31913L4.31168 17.6016ZM17.865 4.04746L15.7767 6.13663L13.86 4.22413L15.9492 2.13496C16.075 2.00938 16.2244 1.90983 16.3887 1.84197C16.553 1.77411 16.7291 1.73929 16.9069 1.73948C17.0847 1.73967 17.2607 1.77488 17.4249 1.8431C17.5891 1.91131 17.7382 2.01119 17.8638 2.13704C17.9893 2.26289 18.0889 2.41225 18.1568 2.57657C18.2246 2.7409 18.2594 2.91698 18.2592 3.09477C18.2591 3.27255 18.2238 3.44856 18.1556 3.61274C18.0874 3.77692 17.9875 3.92605 17.8617 4.05163L17.865 4.04746Z" fill="#98A2B3"/>
              </g>
              <defs>
                <clipPath id="clip0_670_507">
                  <rect width="20" height="20" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
      </div>
    </div>

    <form [formGroup]="formPolicy" novalidate [hidden]="formService.isPolicyFormComplete">

      <div class="alert alert-primary mt-4 mb-4 alert-dismissible fade show" role="alert">
        Дата начала страхования — это следующий день после окончания срока действия прошлого полиса.
        <button type="button" class="btn-close h-auto" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>

      <div class="row mt-4 mortgage-date">
        <div class="col-12 col-md-6 col-lg-4 mb-4">
          <app-form-date-field formControlName="mortgageStartDate" [isShowCalendar]="true" [isReadonly]="isLoading"></app-form-date-field>
        </div>
        <div class="col-12 col-md-6 col-lg-4 mb-4">
          <app-form-date-field formControlName="mortgageEndDate" [isShowCalendar]="true" [isReadonly]="isReadonlyMortgageEndDate"></app-form-date-field>
        </div>
      </div>

      <div class="row text-end justify-content-end">
        <div class="col text-end" *ngIf="!isApproveOffersComplete">
          <button type="button" class="btn btn-primary btn-lg" (click)="onSubmit(false)">Продолжить</button>
        </div>
        <div class="col-auto text-end" *ngIf="isApproveOffersComplete">
          <button type="button" class="btn btn-outline-secondary btn-lg" (click)="formService.isPolicyFormComplete = !formService.isPolicyFormComplete">Отмена</button>
        </div>
        <div class="col-auto text-end" *ngIf="isApproveOffersComplete">
          <button type="button" class="btn btn-primary btn-lg" (click)="onSubmit(true)">Сохранить</button>
        </div>
      </div>
    </form>

  </div>
</div>
