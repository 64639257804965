import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormGroup, UntypedFormGroup, Validators} from "@angular/forms";
import {EMPTY, Subject, Subscription} from "rxjs";
import {FormService} from "../../../../shared/services/form.service";
import {DaData} from "../../../../shared/enums/da-data.enum";
import {BsModalRef, BsModalService, ModalOptions} from "ngx-bootstrap/modal";
import {PdfDocViewComponent} from "../../../../shared/components/pdf-doc-view/pdf-doc-view.component";
import {YandexMetrikaService} from "../../../../shared/services/yandex-metrika.service";
import {debounceTime, switchMap, takeUntil} from "rxjs/operators";
import {DadataService} from "../../../../core/services/dadata.service";
import {checkIsHiddenBaseAmount, checkIsHiddenObjectSquare} from "../../../../shared/functions/checkIsHiddenBaseAmount";
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-insurance-object-form',
  templateUrl: './insurance-object-form.component.html',
  styleUrls: ['./insurance-object-form.component.scss']
})
export class InsuranceObjectFormComponent implements OnInit, OnDestroy {
  // Событие заполненной формы страхователя
  @Output() insuranceFormComplete: EventEmitter<boolean> = new EventEmitter<boolean>();
  // Форма страхователя заполнена
  @Input() isInsuranceFormComplete!: boolean;
  // Уже искали офферов, значит форма заполнена и теперь толкьо сохраняем ее без того что бы идти дальше по степам
  @Input() isApproveOffersComplete!: boolean;
  constructor(
    private readonly modalService: BsModalService,
    public readonly formService: FormService,
    private readonly ym: YandexMetrikaService,
    private readonly dadataService: DadataService) {
  }

  public bankSystemName = environment.partnerSystemName;

  // Форма объекта страхования
  public formInsuranceObject = (this.formService.form.get('insuranceObject') as UntypedFormGroup);
  public form = this.formService.form;
  // Подписка
  private subscription = new Subscription();
  // Индикатор загрузки
  public isLoading = false;
  // DaData системные имена
  public daDataSystemName = DaData;
  private bsModalRef?: BsModalRef;
  private destroy$ = new Subject<void>();

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
    this.form.get('privacy')?.setValue(true);

    // Если адрес квартиры совпадает с адресом заемщика, то дизейблим поле insuranceObjectAddress
    if (this.formInsuranceObject.get('insuranceObjectAddressSameAsInsurer')?.value) {
      this.formInsuranceObject.get('insuranceObjectAddress')?.disable();
    }

    this.subscribeForm();
    this.subscribeHouseNumber();
  }
  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  // --------------------------------------------------------------------------

  // Заполнили форму страхователя
  public onSubmit(isGetOffers: boolean): void {
    this.formInsuranceObject.markAllAsTouched();
    if (this.formInsuranceObject.invalid) return;

    this.formService.isInsuranceFormDisabled = false;

    this.ym.onYandexReachGoal('INSURANCE_OBJECT_GOAL');

    this.insuranceFormComplete.emit(isGetOffers);
  }

  // Подписка на изменение формы
  private subscribeForm(): void {
    const formBorrower = (this.formService.form.get('borrower') as UntypedFormGroup);
    const borrowerAddressFullDaData = formBorrower.get('borrowerAddressFullDaData')?.value;
    const insuranceObjectFormGroup: FormGroup = this.formService.form.get('insuranceObject') as FormGroup;

    this.subscription.add(
      this.formInsuranceObject.get('insuranceObjectAddressSameAsInsurer')?.valueChanges
        .subscribe((value) => {
          const {
            insuranceBaseAmount,
            kadastr,
            insuranceObjectSquare,
            insuranceObjectFullAddressDaData,
          } = insuranceObjectFormGroup.controls;
          // kadastr.reset();
          // insuranceObjectSquare.reset();
          // insuranceBaseAmount.reset();

          if (value) {
            this.changeInsuranceObjectAddress(borrowerAddressFullDaData);
            this.formInsuranceObject.get('insuranceObjectAddress')?.disable();
            this.formInsuranceObject.get('insuranceHouseNumber')?.disable();
          } else {
            this.changeInsuranceObjectAddress(borrowerAddressFullDaData);
            this.formInsuranceObject.get('insuranceObjectAddress')?.enable();
            this.formInsuranceObject.get('insuranceHouseNumber')?.enable();
          }

          // Если адрес квартиры совпадает с адресом заемщика, то предзаполняем рыночную стоимость и количество квадратов
          this.formService.insuranceObjectSquareIsHidden = checkIsHiddenObjectSquare(borrowerAddressFullDaData);
          this.formService.insuranceBaseAmountIsHidden = checkIsHiddenBaseAmount(borrowerAddressFullDaData);
        })
    );
  }

  public showPdfDocModal(src: string): void {
    const initialState = {
      src,
    };
    this.bsModalRef = this.modalService.show(PdfDocViewComponent, {
      initialState,
      class: 'modal-dialog-centered PDFmodal'
    });
  }

  private subscribeHouseNumber(): void {
    const {
      insuranceObjectAddress,
      insuranceHouseNumber,
      insuranceObjectFullAddressDaData
    } = this.formInsuranceObject.controls;

    this.subscription.add(
      insuranceObjectAddress.valueChanges.pipe(
        debounceTime(300),
        switchMap((value) => {
          if (value && insuranceHouseNumber.value) {
            return this.dadataService.suggestionAddress(
              value + ', кв ' + insuranceHouseNumber.value,
              DaData.ADDRESS_FULL
            ).pipe(
              takeUntil(this.destroy$)
            );
          } else {
            return EMPTY;
          }
        })
      ).subscribe((res) => {
        const daData = {value: res[0]?.value, ...res[0]?.data};
        insuranceObjectFullAddressDaData.setValue(daData);
        return this.formInsuranceObject.get('insuranceObjectAddressSameAsInsurer')?.value ? EMPTY : this.setDadataResponse(res);
      })
    );

    this.subscription.add(
      insuranceHouseNumber.valueChanges.pipe(
        debounceTime(300),
        switchMap((value) => {
          if (value && insuranceObjectAddress.value) {
            return this.dadataService.suggestionAddress(
              insuranceObjectAddress.value  + ', кв ' + value,
              DaData.ADDRESS_FULL
            ).pipe(
              takeUntil(this.destroy$)
            );
          } else {
            return EMPTY;
          }
        })
      ).subscribe((res) => {
        const daData = {value: res[0]?.value, ...res[0]?.data};
        insuranceObjectFullAddressDaData.setValue(daData);
        return this.formInsuranceObject.get('insuranceObjectAddressSameAsInsurer')?.value ? EMPTY : this.setDadataResponse(res);
      })
    );

  }

  // Сохраняем данные dadata в форму
  private setDadataResponse(daDataRes: any): void {
    const {
      insuranceObjectFullAddressDaData
    } = this.formInsuranceObject.controls;
    const daData = {value: daDataRes[0]?.value, ...daDataRes[0]?.data};
    insuranceObjectFullAddressDaData.setValue(daData);
    // Скрываем или показываем контрол
    this.formService.insuranceObjectSquareIsHidden = checkIsHiddenObjectSquare(daData);
    this.formService.insuranceBaseAmountIsHidden = checkIsHiddenBaseAmount(daData);
    this.changeInsuranceObjectAddress(daData);
  }

  // Выбрали адрес квартиры
  public changeInsuranceObjectAddress(data: any): void {
    const insuranceObjectFormGroup: FormGroup = this.formService.form.get('insuranceObject') as FormGroup;

    const {
      insuranceBaseAmount,
      kadastr,
      insuranceObjectSquare
    } = insuranceObjectFormGroup.controls;

    if (data?.flat_cadnum) {
      kadastr.setValue(data?.flat_cadnum);
      this.formService.kadastrIsHidden = false;
    } else {
      // kadastr.reset();
      this.formService.kadastrIsHidden = true;
    }

    if (data?.flat_area) {
      const flatArea = data?.flat_area;
      insuranceObjectSquare.setValue(flatArea);
      if (data?.square_meter_price) {
        const price = Number((data?.flat_area * data?.square_meter_price).toString().replace(',', '.')).toLocaleString('ru-RU', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 0,
          useGrouping: true
        });
        insuranceBaseAmount.setValue(data.flat_price ? data.flat_price : price.replace(/\s/g, ''));
      }
    } else {
      // insuranceObjectSquare.reset();
      // insuranceBaseAmount.reset();
    }
  }

  // Статус блокировки "Адрес квартиры совпадает с адресом регистрации заёмщика"
  public getDisabledStatus(): boolean {
    const formBorrower = (this.formService.form.get('borrower') as UntypedFormGroup);
    const borrowerAddressFullDaData = formBorrower.get('borrowerHouseNumber')?.value;
    return !borrowerAddressFullDaData;
  }

}
