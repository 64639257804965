import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AutocompleteComponent} from "src/app/shared/components/autocomplete/autocomplete.component";
import {FormFieldErrorComponent} from "./components/form-field-error/form-field-error.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TypeaheadModule} from "ngx-bootstrap/typeahead";
import {FormDateFieldComponent} from "./components/form-date-field/form-date-field.component";
import {FormNumberFieldComponent} from "./components/form-number-field/form-number-field.component";
import {IsNumericDirective} from "./directives/number.directive";
import {TextMaskModule} from "angular2-text-mask";
import {OfferItemComponent} from './components/offer-item/offer-item.component';
import {FormTextFieldComponent} from "src/app/shared/components/form-text-field/form-text-field.component";
import {HttpClientModule} from "@angular/common/http";
import { SelectedOfferComponent } from './components/selected-offer/selected-offer.component';
import {LoaderOfferComponent} from "./components/loader-offer/loader-offer.component";
import {ModalModule} from "ngx-bootstrap/modal";
import {DateDirective} from "./directives/date.directive";
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {RatingModule} from "ngx-bootstrap/rating";
import { PdfDocViewComponent } from './components/pdf-doc-view/pdf-doc-view.component';
import {PdfViewerModule} from "ng2-pdf-viewer";
import {AlertModule} from "ngx-bootstrap/alert";
import {RouterLink} from "@angular/router";
import { ProgressCircleComponent } from './components/progress-circle/progress-circle.component';


@NgModule({
    declarations: [
        AutocompleteComponent,
        FormFieldErrorComponent,
        FormDateFieldComponent,
        FormNumberFieldComponent,
        IsNumericDirective,
        OfferItemComponent,
        FormTextFieldComponent,
        SelectedOfferComponent,
        LoaderOfferComponent,
        DateDirective,
        PdfDocViewComponent,
        ProgressCircleComponent
    ],
  exports: [
    AutocompleteComponent,
    FormDateFieldComponent,
    FormNumberFieldComponent,
    OfferItemComponent,
    FormTextFieldComponent,
    SelectedOfferComponent,
    LoaderOfferComponent,
    ProgressCircleComponent
  ],
    imports: [
        BsDatepickerModule.forRoot(),
        BrowserAnimationsModule,
        CommonModule,
        ReactiveFormsModule,
        TypeaheadModule,
        TextMaskModule,
        HttpClientModule,
        ModalModule.forRoot(),
        FormsModule,
        RatingModule,
        PdfViewerModule,
        AlertModule,
        RouterLink
    ],
  providers: [
  ]
})
export class SharedModule {
}
