<div class="progress-block card border-0 rounded-5 mt-3 mt-sm-3">
  <div class="card-body">
    <div class="progress-container">
      <svg
        class="progress-circle"
        [attr.width]="(radius + strokeWidth) * 2"
        [attr.height]="(radius + strokeWidth) * 2"
      >
        <!-- Задний круг (серый) -->
        <circle
          class="progress-circle-bg"
          [attr.r]="radius"
          [attr.cx]="radius + strokeWidth"
          [attr.cy]="radius + strokeWidth"
          [attr.stroke-width]="strokeWidth"
          fill="none"
        ></circle>

        <!-- Передний круг (синий, прогресс) -->
        <circle
          class="progress-circle-fg"
          [attr.r]="radius"
          [attr.cx]="radius + strokeWidth"
          [attr.cy]="radius + strokeWidth"
          [attr.stroke-width]="strokeWidth"
          [attr.stroke-dasharray]="getDashArray()"
          fill="none"
        ></circle>
      </svg>
      <!-- Отображение таймера в формате 'MM:SS' -->
      <div class="timer">{{ paymentCountdown }}</div>

      <div class="progress-circle-text">
        Подбираем варианты
      </div>
    </div>
  </div>
</div>
