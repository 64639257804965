import {Component, Input, OnInit} from '@angular/core';
import {MortgageService} from "../../services/mortgage.service";
import { environment } from '../../../../environments/environment';

// Сервисы
import {FormFilterService} from "../../services/form-filter.service";

// Перечисления
import {NavigationService} from "../../services/navigation.service";


@Component({
  selector: 'app-selected-offer',
  templateUrl: './selected-offer.component.html',
  styleUrls: ['./selected-offer.component.scss']
})
export class SelectedOfferComponent implements OnInit {

  public bankSystemName = environment.partnerSystemName;
  @Input() offer: any;
  // Остаток по кредиту
  @Input() creditRemain: string = this.formFilterService.form.get('creditRemain')?.value;

  constructor(public readonly mortgageService: MortgageService,
              public readonly formFilterService: FormFilterService,
              public readonly navigationService: NavigationService) {
  }

  get getPreapprovePremiumAmount(): number {
    return this.offer.preapprovePremiumAmount.toLocaleString('ru-RU', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
      useGrouping: true
    });
  }

  get getCreditRemainAmount(): string {
    return Number(this.creditRemain.toString().replace(',', '.')).toLocaleString('ru-RU', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
      useGrouping: true
    });
  }

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
  }
  // --------------------------------------------------------------------------

  public navigateToBack(event: Event): void {
    event.preventDefault();
    this.navigationService.navigate('/');
  }
}
