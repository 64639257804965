import { Inject, Injectable } from '@angular/core';
import {BehaviorSubject, concatMap, Observable, of} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Router} from "@angular/router";
import { v4 as uuidv4 } from 'uuid';

// Сервисы
import {FormService} from '../../shared/services/form.service';
import {HttpService} from '../../shared/services/http.service';
import {FormFilterService} from "../../shared/services/form-filter.service";
import {SettingsService} from './settings.service';

// Интерфейсы
import {IUrlParams} from '../../shared/interface/url-params.interface';
import {IApplicationRequest, IApplicationResponse} from "../../shared/interface/application.interface";
import { environment } from '../../../environments/environment';
import { CalendarHelper } from '../../shared/helpers/calendarHelper';

@Injectable({
  providedIn: 'root'
})
export class AppService extends HttpService {

  constructor(@Inject(HttpClient) protected override http: HttpClient,
              private readonly settingsService: SettingsService,
              private readonly formService: FormService,
              private readonly formFilterService: FormFilterService,
              private readonly router: Router) {
    super(http);
    this.checkIsArmApplication();
  }

  // тип заявки - новая\пролонгация etc
  private applicationType = 'New';
  // Анкета открыта из арма
  private _isArmApplication = false;
  // Анкета из арма
  private _armApplication: BehaviorSubject<any | null> = new BehaviorSubject<any | null>(null);
  armApplication$ = this._armApplication.asObservable();
  // Парметр src из ссылки, например OffersRecivedEmail
  public srcUrlParam: string = '';
  // Параметр offerId из url
  public offerIdFromUrlParams: string = '';
  // UID заявки
  public applicationId!: string;
  // UID клиента
  public clientId!: string;
  // Статус заявки
  public setWidgetDisplayedStatus = false;
  // АБ тест
  public alfaIdABTestingGroup!: number;
  // Данные из кэша есть или нет
  public dateFromCacheIsNull = false;

  // URL api окружения
  // private apiUrl = environment.apiUrl;
  // URL car info api
  // private carInfoApi = environment.carInfoApi;
  private armEventReceived = false;

  public checkIsArmApplication() {
    this._isArmApplication = this.isOpenFromArm();

    if (this._isArmApplication) {
      window.addEventListener('message', (ev: MessageEvent) => {
        if (!this.armEventReceived && ev.data && ev.data.type && ev.data.type === 'MortgageApplication') {
          this.armEventReceived = true;
          console.log("AppService::checkIsArmApplication::windowEvent");
          this.settingsService.apiKey = ev.data.apikey;
          this.applicationType = ev.data.applicationType;
          this._armApplication.next(ev.data.application);
        }
      });

      window.opener.postMessage({type: 'MortgageApplication'}, '*');
    }
  }

  public get armApplication() {
    return this._armApplication.value;
  }

  private isOpenFromArm(): boolean {
       try {
           if (document.referrer == null || document.referrer == '') {
               return false;
           }

           var url = new URL(document.referrer);
           return url.hostname.toLowerCase() == environment.armHost;
       } catch (e) {
           return false;
       }
  }

  get isArmApplication(): boolean {
    return this._isArmApplication;
  }

  // --------------------------------------------------------------------------
  // Запросы вызываются при инициализации, старт запросов в OnInit app.component
  public loadApp(): Observable<any> {
    this.dateFromCacheIsNull = false;
   // Сначала смотрим какие у нас данные есть, проверяем url, localStorage
   return this.getApplicationIdFromQueryParams()
     .pipe(
       concatMap((res) => res?.offerId && res?.offerId !== '0' ? this.getMortgageCacheByApplicationId(res) : of(null)),
       map((res) => {
         if (res && res.result && res.value) {
           this.formService.setFormDataFromCache(res.value);
           this.formFilterService.setFilterFormDataFromCache(res.value);
           this.formFilterService.saveFilterFormToLocalStorage();
           this.formService.saveFormToLocalStorage();
         } else {
           this.dateFromCacheIsNull = true;
         }
         return res;
       }),
       concatMap(() => this.getClientId()),
       concatMap(() => this.createApplication()),
       take(1)
     );
  }

  // Получаем данные анкеты с кэша
  private getMortgageCacheByApplicationId(urlParams: IUrlParams): Observable<any> {
    const request = {
      apiKey: this.settingsService.apiKey,
      applicationId: urlParams.applicationId,
    }
    return this.http.post(environment.apiUrl + 'Client/GetMortgageCacheByApplicationId', request)
    // return this.http.post(environment.apiUrl + 'Client/GetCache', request)
  }

  // Получаем данные из параметров url
  public getApplicationIdFromQueryParams(): Observable<IUrlParams> {
    // Создаем ссылку
    const url: URL = new URL(window.location.href);
    // Получаем параметры
    const params: URLSearchParams = url.searchParams;
    // Получаем параметры из url
    const urlParams: IUrlParams = {
      applicationId: ''
    };

    // Если есть offersRecivedEmail в параметрах url, то используем его
    const src: string | null = params.get('src');
    if (src) {
      this.srcUrlParam = src;
      urlParams.src = src;
    }

    // Если есть apiKey в параметрах url, то используем его
    const apiKey: string | null = params.get('apikey');
    if (params && apiKey) {
      this.settingsService.apiKey = apiKey;
      urlParams.apiKey = apiKey;
    }

    // Если есть applicationId в параметрах url, то используем его
    const applicationId: string | null = params.get('applicationId');
    if (applicationId) {
      urlParams.applicationId = applicationId;
      this.formService.form.get('applicationId')?.setValue(applicationId);
    }

    // Если есть clientId в параметрах url, то используем его
    const clientId: string | null = params.get('clientId');
    if (clientId) {
      localStorage.setItem('clientId', clientId);
      urlParams.clientId = clientId;
      this.clientId = clientId;
    } else {
      this.clientId = localStorage.getItem('clientId') || '';
    }

    // Если есть cpaClientUid в параметрах url, то используем его
    const cpaClientUid: string | null = params.get('cpaClientId');
    if (cpaClientUid) {
      this.formService.form.get('cpaClientId')?.setValue(cpaClientUid);
    }

    // Если есть cpaClientUid2 в параметрах url, то используем его
    const cpaClientUid2: string | null = params.get('cpaClientId2');
    if (cpaClientUid2) {
      this.formService.form.get('cpaClientId2')?.setValue(cpaClientUid2);
    }

    // Если есть loyalty в параметрах url, то используем его
    const loyalty: string | null = params.get('loyalty');
    if (loyalty) {
      this.formService.form.get('cpaClientUid')?.setValue(loyalty);
    }

    // Если есть offerId в параметрах url, то используем его
    const offerId: string | null = params.get('offerId');
    if (offerId) {
      urlParams.offerId = offerId;
      this.offerIdFromUrlParams = offerId;
    }

    return new Observable(subscriber => subscriber.next(urlParams));
  }

  // Получаем данные из параметров url
  public getUrlParams(): IUrlParams {
    // Создаем ссылку
    const url: URL = new URL(window.location.href);
    // Получаем параметры
    const params: URLSearchParams = url.searchParams;
    // Получаем параметры из url
    const urlParams: IUrlParams = {};

    // Преобразуем все ключи параметров в нижний регистр
    const lowerCaseParams: Record<string, string | null> = {};
    params.forEach((value, key) => {
      lowerCaseParams[key.toLowerCase()] = value;
    });

    // Если есть src в параметрах url, то используем его
    const src: string | null = lowerCaseParams['src'];
    if (src) {
      this.srcUrlParam = src;
      urlParams.src = src;
    }

    // Если есть apiKey в параметрах url, то используем его
    const apiKey: string | null = lowerCaseParams['apikey'];
    if (apiKey) {
      this.settingsService.apiKey = apiKey;
      urlParams.apiKey = apiKey;
    }

    // Если есть applicationId в параметрах url, то используем его
    const applicationId: string | null = lowerCaseParams['applicationid'];
    if (applicationId) {
      urlParams.applicationId = applicationId;
      this.formService.form.get('applicationId')?.setValue(applicationId);
    }

    // Если есть clientId в параметрах url, то используем его
    const clientId: string | null = lowerCaseParams['clientid'];
    if (clientId) {
      urlParams.clientId = clientId;
    }

    // Если есть cpaClientUid в параметрах url, то используем его
    const cpaClientUid: string | null = lowerCaseParams['cpaclientuid'];
    if (cpaClientUid) {
      urlParams.CpaClientUid = cpaClientUid;
      this.formService.form.get('cpaClientId')?.setValue(cpaClientUid);
    }

    // Если есть cpaClientUid в параметрах url, то используем его
    const cpaClientId: string | null = lowerCaseParams['cpaclientid'];
    if (cpaClientId) {
      urlParams.CpaClientId = cpaClientId;
    }

    // Если есть cpaClientUid2 в параметрах url, то используем его
    const cpaClientUid2: string | null = lowerCaseParams['cpaclientuid2'];
    if (cpaClientUid2) {
      urlParams.CpaClientUid2 = cpaClientUid2;
      this.formService.form.get('cpaClientId2')?.setValue(cpaClientUid2);
    }

    // Если есть loyalty в параметрах url, то используем его
    const loyalty: string | null = lowerCaseParams['loyalty'];
    if (loyalty) {
      urlParams.loyalty = loyalty;
    }

    // Если есть creditRemain в параметрах url, то используем его
    const creditRemain: string | null = lowerCaseParams['creditremain']?.replace(/\s/g, '') || '';
    if (creditRemain) {
      urlParams.CreditRemain = creditRemain;
      this.formFilterService.form.get('creditRemain')?.setValue(creditRemain.replace('.', ','));
      this.formService.form.get('mortgage')?.get('mortgageCreditRemain')?.setValue(creditRemain.replace('.', ','));
    }

    // Если есть insuredBirthDate в параметрах url, то используем его
    const insuredBirthDate: string | null = lowerCaseParams['insuredbirthdate'];
    if (insuredBirthDate) {
      urlParams.insuredBirthDate = insuredBirthDate;
      this.formFilterService.form.get('insuredBirthDate')?.setValue(insuredBirthDate);
    }

    // Если есть insuredGender в параметрах url, то используем его
    const insuredGender: string | null = lowerCaseParams['insuredgender'];
    if (insuredGender) {
      urlParams.insuredGender = insuredGender;
      this.formFilterService.form.get('insuredGender')?.setValue(insuredGender);
    }

    // Если есть contractNumber в параметрах url, то используем его
    const contractNumber: string | null = lowerCaseParams['contractnumber'];
    if (contractNumber) {
      urlParams.contractNumber = contractNumber;
      this.formService.form.get('mortgage')?.get('mortgageCreditNumber')?.setValue(contractNumber);
    }

    // Если есть mortgageStartDate в параметрах url, то используем его
    const mortgageStartDate: string | null = lowerCaseParams['mortgagestartdate'];
    if (mortgageStartDate) {
      urlParams.mortgageStartDate = mortgageStartDate;
      this.formService.form.get('mortgagePolicyDate')?.get('mortgageStartDate')?.setValue(mortgageStartDate);
    }

    const platformId: string | null = lowerCaseParams['platformid'];
    if (platformId) {
      urlParams.platformId = platformId;
    }

    const webMasterID: string | null = lowerCaseParams['webmasterid'];
    if (webMasterID) {
      urlParams.webMasterID = webMasterID;
    }

    const utmSource: string | null = lowerCaseParams['utm_source'];
    if (utmSource) {
      urlParams.utmSource = utmSource;
    }

    const utmMedium: string | null = lowerCaseParams['utm_medium'];
    if (utmMedium) {
      urlParams.utmMedium = utmMedium;
    }

    const utmTerm: string | null = lowerCaseParams['utm_term'];
    if (utmTerm) {
      urlParams.utmTerm = utmTerm;
    }

    const utmCampaign: string | null = lowerCaseParams['utm_campaign'];
    if (utmCampaign) {
      urlParams.utmCampaign = utmCampaign;
    }

    const utmContent: string | null = lowerCaseParams['utm_content'];
    if (utmContent) {
      urlParams.utmContent = utmContent;
    }

    // Если есть offerId в параметрах url, то используем его
    const offerId: string | null = lowerCaseParams['offerid'];
    if (offerId) {
      urlParams.offerId = offerId;
      this.offerIdFromUrlParams = offerId;
    }

    return urlParams;
  }


  // Создаем новую заявку
  public createApplication(): Observable<IApplicationResponse | null> {
    // Создаем ссылку
    const url: URL = new URL(window.location.href);
    const urlParams = this.getUrlParams();
    const request: IApplicationRequest = {
      apiKey: this.settingsService.apiKey,
      productType: 'Mortgage',
      successPaymentUrl: url.origin + '/success',
      returnClientChannelType: this.srcUrlParam || undefined,
      clientId: this.clientId,
      channelType: "Widget",
      SourceUrl: url.href,
      source: urlParams.src,
      platformId: urlParams.platformId,
      webMasterID: urlParams.webMasterID,
      cpaClientUid: urlParams.CpaClientUid,
      cpaClientUid2: urlParams.CpaClientUid2,
      utmSource: urlParams.utmSource,
      utmMedium: urlParams.utmMedium,
      utmTerm: urlParams.utmTerm,
      utmCampaign: urlParams.utmCampaign,
      utmContent: urlParams.utmContent,
      loyalty: urlParams.loyalty,
      localTime: CalendarHelper.getLocalDateTimeString(),
    }

    return this.post(environment.apiUrl + 'app/new', request)
      .pipe(
        map((res) => {
          if (res.value && res.value?.abTestingGroups && res.value?.abTestingGroups.alfaIdABTestingGroup) {
            this.alfaIdABTestingGroup = res.value?.abTestingGroups.alfaIdABTestingGroup;
          }
          if (res.value && res.value.applicationId) {
            this.applicationId = res.value.applicationId;
          }
          return res;
        }),
        concatMap(() => this.setWidgetDisplayed(this.getUrlParams())),
        map((res) => {
          this.setWidgetDisplayedStatus = true;
          return res;
        })
      );

  }

  setWidgetDisplayed(urlParams: IUrlParams): Observable<any> {
       const request = {
           apiKey: this.settingsService.apiKey,
           applicationId: this.applicationId
       };

       if (urlParams && urlParams?.closeNewRequest && urlParams.applicationId) {
        this.applicationId = urlParams.applicationId;
           this.formService.form.get('applicationId')?.setValue(urlParams.applicationId);
           return new Observable(subscriber => subscriber.next({applicationId: urlParams.applicationId}));
       } else {
         this.formService.form.get('applicationId')?.setValue(this.applicationId);
           return this.post(environment.apiUrl + 'app/SetStatusWidgetDisplayed', request)
               .pipe(take(1));
       }
   }

  // Получаем clientId из localStorage или генерируем новый
  private getClientId(): Observable<string> {
      const clientIdFromLocalStorage = localStorage.getItem('clientId');
      // Если clientId есть в localStorage, то возвращаем его
      if (clientIdFromLocalStorage) {
        this.clientId = clientIdFromLocalStorage;
          return new Observable(subscriber => subscriber.next(clientIdFromLocalStorage));
      } else {
          // Иначе, генерируем новый clientId и сохраняем его в localStorage
          return new Observable(subscriber => {
              const generateClientId = uuidv4();
              this.clientId = generateClientId;
              localStorage.setItem('clientId', generateClientId);
              subscriber.next(generateClientId);
          });
      }
  }

  public error(message: string | null = null, logged = false): void {
    if (message != null) {
      this._errorMessages.push(message);
    }

    if (!logged) {
      this.postLocal('log', {
        applicationId: this.formService.form?.get('applicationId')?.value,
        message
      })
        .pipe(take(1))
        .subscribe({
          next: () => {
          },
          error: () => {
          }
        });
    }

    console.log('тут раньше в осаго был переход на страницу ошибки');
  }

}
