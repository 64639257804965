import {Injectable} from '@angular/core';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {combineLatest, Subscription} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  bsModalRef?: BsModalRef;
  subscriptions: Subscription[] = [];
  messages: string[] = [];

  constructor(private modalService: BsModalService,
              public readonly modalRef: BsModalRef,) {
    this.bsModalRef = modalRef;
  }

  public openModal(component: any, config: any) {
    this.messages = [];

    this.bsModalRef = this.modalService.show(component, config);
    let _combine;
    // if (this.bsModalRef?.onHide && this.bsModalRef?.onHidden) {
    //   _combine = combineLatest(
    //     this.bsModalRef.onHide,
    //     this.bsModalRef.onHidden
    //   ).subscribe(() => this.changeDetection.markForCheck());
    // }

    if (this.bsModalRef?.onHide) {
      this.subscriptions.push(
        this.bsModalRef.onHide.subscribe((reason: string | any) => {
          if (typeof reason !== 'string') {
            reason = `onHide(), modalId is : ${reason.id}`;
          }
          const _reason = reason ? `, dismissed by ${reason}` : '';
          this.messages.push(`onHide event has been fired${_reason}`);
        })
      );
    }

    if  (this.bsModalRef?.onHidden) {
      this.subscriptions.push(
        this.bsModalRef.onHidden.subscribe((reason: string | any) => {
          if (typeof reason !== 'string') {
            reason = `onHide(), modalId is : ${reason.id}`;
          }
          const _reason = reason ? `, dismissed by ${reason}` : '';
          this.messages.push(`onHidden event has been fired${_reason}`);
          this.unsubscribe();
        })
      );
    }

    if (_combine) {
      this.subscriptions.push(_combine);
    }
  }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }
}
