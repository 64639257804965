import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";
import {Subscription} from "rxjs";
import {FormService} from "../../../../shared/services/form.service";
import {conformToMask} from "angular2-text-mask";
import {MASKS} from "../../../../shared/constants/masks";
import {IMask} from "../../../../shared/interface/masks.interface";
import {MortgageService} from "../../../../shared/services/mortgage.service";
import {YandexMetrikaService} from "../../../../shared/services/yandex-metrika.service";

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit, OnDestroy {
  // Событие заполненной формы контактов
  @Output() contactFormComplete: EventEmitter<boolean> = new EventEmitter<boolean>();
  // Форма контактов заполнена
  @Input() isContactFormComplete!: boolean;
  // Уже искали офферов, значит форма заполнена и теперь толкьо сохраняем ее без того что бы идти дальше по степам
  @Input() isApproveOffersComplete!: boolean;
  constructor(
    public readonly formService: FormService,
    private readonly mortgageService: MortgageService,
    private readonly ym: YandexMetrikaService) {
  }

  // Форма контактов
  public formContacts = (this.formService.form.get('contacts') as UntypedFormGroup);
  // Подписка
  private _subscription = new Subscription();
  // Индикатор загрузки
  public isLoading = false;

  get phoneMask(): string {
    const phoneMask = conformToMask(
      this.formContacts.value?.phone,
      MASKS.find((item: IMask) => item.systemName === 'phone')?.mask,
      {guide: false}
    );
    return phoneMask.conformedValue;
  }

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
  }
  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
  // --------------------------------------------------------------------------

  // Заполнили форму контактов
  public onSubmit(isSaveForm: boolean): void {
    this.formContacts.markAllAsTouched();
    if (this.formContacts.invalid) return;

    const phone = this.formContacts.value?.phone;
    const email = this.formContacts.value?.email;

    if (!this.isApproveOffersComplete) {
      this.mortgageService.sendContacts(phone, email).subscribe();
    }

    this.formService.isContactFormComplete = true;

    this.ym.onYandexReachGoal('CONTACTS_GOAL');

    this.contactFormComplete.emit(isSaveForm);
  }

}
