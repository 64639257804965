import {
    HttpHandler,
    HttpRequest,
    HttpEvent,
    HttpErrorResponse,
    HttpInterceptor,
    HttpClient
} from '@angular/common/http';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { HttpService } from './http.service';
import { FormService } from './form.service';
import {YandexMetrikaService} from "./yandex-metrika.service";

@Injectable({
    providedIn: 'root'
})
export class HttpErrorInterceptor extends HttpService implements HttpInterceptor, OnDestroy {
    private _subscription = new Subscription();

    constructor(
        @Inject(HttpClient) protected override http: HttpClient,
        private readonly ym: YandexMetrikaService,
        private readonly formService: FormService) {
        super(http);
    }

    // --------------------------------------------------------------------------
    // Инициализация
    public ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }
    // --------------------------------------------------------------------------

    private handleError(error: HttpErrorResponse): void {
        this._subscription.add(
            this.postLocal('log', {
                applicationId: this.formService.form?.get('applicationId')?.value,
                message: JSON.stringify(error)
            })
                .subscribe({
                    next: () => {
                    },
                    error: () => {
                    }
                })
        );
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error != null && error.url != null && error.url.toLowerCase().indexOf('/log') === -1) {
                    this.handleError(error);
                }

                return throwError(error);
            })
        ) as Observable<HttpEvent<any>>;
    }
}
