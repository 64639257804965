import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ActivatedRoute } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {
    // apiKey
    public apiKey = environment.apiKey;

    constructor() {
    }
}
