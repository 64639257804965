<div class="modal-body">
  <div id="outerContainer">
    <div class="pdf-container">
      <pdf-viewer
        [src]="src"
        [rotation]="0"
        [original-size]="false"
        [show-all]="true"
        [fit-to-page]="false"
        [zoom]="1"
        [zoom-scale]="'page-fit'"
        [stick-to-page]="false"
        [render-text]="false"
        [autoresize]="true"
        [show-borders]="false"
        style="width: 100%; height: 70vh;"
      ></pdf-viewer>
    </div>
  </div>
</div>
<div class="modal-footer" *ngIf="modalService.bsModalRef">
  <div class="row">
    <div class="col-12 col-sm-auto">
      <button type="button" class="btn btn-outline-secondary btn-lg w-100 mb-2 mb-sm-0" (click)="bsModalRef.hide()">Закрыть</button>
    </div>
  </div>
</div>
