<div class="row align-items-center">
  <div class="col-auto">
    <a (click)="navigateToBack($event)" href="#">
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M31.6667 18.3337H15L20.4833 12.8503C20.6395 12.6954 20.7635 12.5111 20.8482 12.308C20.9328 12.1049 20.9763 11.887 20.9763 11.667C20.9763 11.447 20.9328 11.2291 20.8482 11.026C20.7635 10.8229 20.6395 10.6386 20.4833 10.4837C20.1711 10.1733 19.7486 9.99902 19.3083 9.99902C18.868 9.99902 18.4456 10.1733 18.1333 10.4837L10.9833 17.6503C10.3574 18.2726 10.0037 19.1177 10 20.0003C10.0081 20.8772 10.3614 21.7155 10.9833 22.3337L18.1333 29.5003C18.2887 29.6546 18.473 29.7768 18.6756 29.8599C18.8782 29.943 19.0952 29.9854 19.3142 29.9846C19.5332 29.9838 19.7499 29.9399 19.9519 29.8554C20.154 29.7709 20.3374 29.6474 20.4917 29.492C20.646 29.3366 20.7682 29.1523 20.8512 28.9497C20.9343 28.7471 20.9767 28.5301 20.9759 28.3111C20.9752 28.0921 20.9313 27.8754 20.8467 27.6734C20.7622 27.4714 20.6387 27.288 20.4833 27.1337L15 21.667H31.6667C32.1087 21.667 32.5326 21.4914 32.8452 21.1788C33.1577 20.8663 33.3333 20.4424 33.3333 20.0003C33.3333 19.5583 33.1577 19.1344 32.8452 18.8218C32.5326 18.5093 32.1087 18.3337 31.6667 18.3337Z"
          fill="#626F80"/>
      </svg>
    </a>
  </div>
  <div class="col">
    <h3 class="mt-3 mb-3">Оформление полиса</h3>
  </div>
</div>
<div class="form mb-4">

  <app-selected-offer [offer]="mortgageService.selectedOffer"></app-selected-offer>

  <app-contact-form (contactFormComplete)="contactFormComplete($event)"
                    [isApproveOffersComplete]="isApproveOffersComplete"></app-contact-form>

  <app-borrower-form (borrowerFormComplete)="borrowerFormComplete($event)"
                     [isBorrowerFormDisabled]="formService.isBorrowerFormDisabled"
                     [isApproveOffersComplete]="isApproveOffersComplete"></app-borrower-form>

  <app-mortgage-form (mortgageFormComplete)="mortgageFormComplete($event)"
                     [isMortgageFormDisabled]="formService.isMortgageFormDisabled"
                     [isApproveOffersComplete]="isApproveOffersComplete"></app-mortgage-form>

  <app-policy-date-form (policyFormComplete)="policyFormComplete($event)"
                        [isPolicyFormDisabled]="formService.isPolicyFormDisabled"
                        [isApproveOffersComplete]="isApproveOffersComplete"></app-policy-date-form>

  <app-insurance-object-form (insuranceFormComplete)="insuranceFormComplete($event)"
                             [isApproveOffersComplete]="isApproveOffersComplete"></app-insurance-object-form>

  <app-loader-offer *ngIf="isLoading && agreement" [offer]="mortgageService.selectedOffer"></app-loader-offer>




  <alert type="warning" *ngIf="(!isApproveOffer || isError) && !isLoading && agreement" class="d-block mt-4">
    <strong>Полис не подтверждён</strong>
    <p>Попробуйте выбрать другое предложение. Мы сохранили ваши данные, чтобы не заполнять их заново.</p>
    <button type="button" class="btn btn-primary btn-lg"
            routerLink="/" queryParamsHandling="merge">Выбрать другое предложение
    </button>
  </alert>


  <div class="offers-list" *ngIf="!isLoading && !(!isApproveOffer || isError)">
    <div class="card mt-2 mt-sm-4 border-light h-auto shadow-sm rounded-4 ozon-card" *ngIf="isApproveOffersComplete && bankSystemName === 'ozon'">
      <div class="card-body p-2 p-sm-4">
        <div class="row align-items-center card-form-block">
          <div class="col-auto card-form-block-icon">
            <img src="assets/landingFiles/kopeck.svg" alt="Kopeck">
          </div>
          <div class="col-9 p-0">
            <div>Зачислим до 10% от стоимости страховки на карту Ozon Банка в течение 30 дней после вступления полиса в силу</div>
          </div>
        </div>
      </div>
    </div>
    <app-offer-item *ngFor="let item of offers"
                    [offer]="item"
                    [isApproveOffer]="isApproveOffer"
                    [isRepeatOffers]="!isApproveOffer"
                    (triggerNewGetOffers)="triggerNewGetOffers()"></app-offer-item>
  </div>

</div>
<!--<pre>{{formService.form.value | json}}</pre>-->
