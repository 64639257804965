import {AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Input, Output} from '@angular/core';
import {Observable, Subscription, of} from "rxjs";
import {FormFilterService} from "../../../../../shared/services/form-filter.service";
import {MortgageService} from "../../../../../shared/services/mortgage.service";
import {
  IBanksAndProduct,
  IMortgageProductsResponce
} from "../../../../../shared/interface/mortgage-products.interface";
import {IFormFilter} from "../../../../../shared/interface/form-filter.interface";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {OfflineFormComponent} from "../../offline-form/offline-form.component";
import {finalize, switchMap, take} from "rxjs/operators";
import {ActivatedRoute, Router} from "@angular/router";
import {FormService} from "../../../../../shared/services/form.service";
import {FormControl, FormGroup} from "@angular/forms";
import { environment } from '../../../../../../environments/environment';
import { AppService } from '../../../../../core/services/app.service';
import {CONTENT} from "../../../../../shared/constants/content";
import {PdfDocViewComponent} from "../../../../../shared/components/pdf-doc-view/pdf-doc-view.component";
import {YandexMetrikaService} from "../../../../../shared/services/yandex-metrika.service";
import {ModalService} from "../../../../../shared/services/modal.service";

@Component({
  selector: 'app-select-company-filter',
  templateUrl: './select-company-filter.component.html',
  styleUrls: ['./select-company-filter.component.scss']
})
export class SelectCompanyFilterComponent implements OnInit, AfterViewInit, OnDestroy {

  // Передаем полученных офферов
  @Output() changeFormValue: EventEmitter<IFormFilter> = new EventEmitter<IFormFilter>();
  // Индикатор загрузки
  @Input() isLoadingCalculate!: boolean;

  constructor(public readonly formFilterService: FormFilterService,
              public readonly formService: FormService,
              public readonly mortgageService: MortgageService,
              private readonly bsModalService: BsModalService,
              private readonly modalService: ModalService,
              private readonly route: ActivatedRoute,
              private readonly appService: AppService,
              private readonly ym: YandexMetrikaService,
              private readonly router: Router) {
  }

  // Форма фильтра
  public filterForm = this.formFilterService.form;
  // Форма анкеты
  public form = this.formService.form;
  // Индикатор загрузки
  public isLoading = false;
  // Подписка
  private subscription: Subscription = new Subscription();

  creditRemain: FormControl = (this.form.get('borrower') as FormGroup)?.controls['creditRemain'] as FormControl;

  // Список доступных продуктов
  public mortgageProductList: IBanksAndProduct[] = [];
  // Ссылка на модальное окно
  private bsModalRef?: BsModalRef;
  // Выбрать другой банк кроме партнерского, нельзя
  public onlyPartnerBank = environment.onlyPartnerBank;
  // Выбрать другой банк кроме партнерского, нельзя
  public partnerBankId = environment.partnerBankId;
  // Системное имя банка
  public bankSystemName = environment.partnerSystemName;
  // Данные CONTENT
  public content = CONTENT;

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
    this.getMortgageProductList();
    this.subscribeForm();
    // Сразу ищем предложения после того как не получили апрув
    // if (this.mortgageService.selectedBank) {
    //   setTimeout(() => {
    //     this.changeFormValue.emit(this.filterForm.value);
    //     this.calculate();
    //   }, 300)
    // }
    this.mortgageService.draftLink = '';
    this.mortgageService.additionalPolicyDraft = '';
  }

  public ngAfterViewInit(): void {
    this.ym.onYandexReachGoal('MAIN_GOAL');
  }

  // Уничтожение
  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  // --------------------------------------------------------------------------

  // Подписка на изменение формы
  private subscribeForm(): void {
    const {
      insuredGender,
    } = this.filterForm.controls;

    const borrowerFormGroup: FormGroup = this.form.get('borrower') as FormGroup;

    const {
      weight,
      height
    } = borrowerFormGroup?.controls;

    this.subscription.add(
      insuredGender.valueChanges
        .subscribe((value) => {
          weight.setValue(value === 'Male' ? 85 : 60);
          height.setValue(value === 'Male' ? 180 : 175);
        })
    );
  }

  // Получаем список доступных продуктов
  public getMortgageProductList(): void {
    console.log("getMortgageProductList start");
    this.isLoading = true;
    if (this.formFilterService.allMortgageProductList.length > 0) {
      this.mortgageProductList = this.formFilterService.getUniqueInsurerTypeBankProducts(this.formFilterService.allMortgageProductList);
      if (this.onlyPartnerBank) this.filterProductList();
      this.isLoading = false;
    } else {
      this.subscription.add(
        this.checkArmApplication()
          .pipe(
            take(1),
            switchMap(() => this.mortgageService.getMortgageProducts()),
            finalize(() => {
              console.log("getMortgageProductList finalize");
              this.isLoading = false;
            })
          )
          .subscribe((res: IMortgageProductsResponce) => {
              if (res.result && res.value) {
                this.formFilterService.allMortgageProductList = res.value;
                this.mortgageProductList = this.formFilterService.getUniqueInsurerTypeBankProducts(res.value);

                this.setDefaultBank();

                this.getParamsFromUrl();

                const urlParams = this.appService.getUrlParams();

                if (urlParams.offerId !== '0') {
                  this.router.navigate([], { queryParams: {applicationId: this.appService.applicationId, offerId: 0}, queryParamsHandling: 'merge' });
                  const productId = this.filterForm.get('productId')?.value;
                  const bank = this.formFilterService.allMortgageProductList.find((item) => item.productId === productId);
                  if (bank) {
                    this.filterForm.get('product')?.setValue(bank?.productType);
                    this.selectBank(bank);
                    this.calculate();
                  }
                } else {
                  this.getFilterDataFromLocalStorage();
                }
                if (this.onlyPartnerBank) this.filterProductList();

                this.setProductType();
              }
            })
      );
    }

  }

  private checkArmApplication(): Observable<any> {
    if (!this.appService.isArmApplication)
      return of({});

    return this.appService.armApplication$;
  }

  // Фильтруем даныне продуктов и банков
  private filterProductList(): void {
    this.mortgageProductList = this.mortgageProductList.filter((item) => item.bankId === this.partnerBankId)
  }

  // Отправляем данные формы фильтра для поиска офферов
  public calculate(): void {
    this.filterForm.markAllAsTouched();
    if (this.filterForm.invalid) return;

    const product = this.formFilterService.allMortgageProductList.find((item: any) => {
      return item.bankId === this.filterForm.value.bankId
        && item.productType.toLowerCase() === this.filterForm.value.product.toLowerCase()
        && item.mortgagePropertyTypes.find((mortgagePropertyType: string) => mortgagePropertyType.toLowerCase() === this.filterForm.value.mortgagePropertyType.toLowerCase())
    });

    this.ym.onYandexReachGoal('FINISH_FILTER_GOAL');

    if (product) {
      this.filterForm.controls['productId']?.setValue(product.productId);
      if (this.filterForm.invalid && !this.filterForm.value.productId) return;

      this.changeFormValue.emit(this.filterForm.value);
    } else {
      if (this.filterForm.invalid) return;
      this.bsModalRef = this.bsModalService.show(OfflineFormComponent,
        { class: 'offlineForm gray modal-lg' });
    }

    if (!this.appService.isArmApplication)
      this.formFilterService.saveFilterFormToLocalStorage()
  }

  // Выбрали банк
  public selectBank(event: IBanksAndProduct): void {
    this.mortgageService.selectedBank = event;
  }

  // Проверяем даныне фильтр в локальном хранилище
  private getFilterDataFromLocalStorage(): void {

    const {
      mortgagePropertyType,
      bankId,
      creditRemain,
      insuredBirthDate,
      insuredGender,
      product,
      productId,
    } = this.filterForm.controls;

    // Получаем данные из локального хранилища, если они есть
    const filterData = this.appService.isArmApplication
      ? this.appService.armApplication?.filter
      : this.formFilterService.getFilterFromLocalStorage();

    if (filterData) {
      // mortgagePropertyType.setValue(filterData.mortgagePropertyType);
      if (filterData.creditRemain) creditRemain.patchValue(filterData.creditRemain);
      if (filterData.insuredBirthDate) insuredBirthDate.setValue(filterData.insuredBirthDate);
      if (filterData.insuredGender) insuredGender.setValue(filterData.insuredGender);
      if (filterData.product) product.setValue(filterData.product);
      if (filterData.productId) productId.setValue(filterData.productId);
      if (filterData.bankId) bankId.setValue(filterData.bankId);
    }

    if (filterData && filterData.productId) {
      const bank = this.formFilterService.allMortgageProductList.find((bank) => bank.productId === filterData.productId);
      if (bank) {
        this.selectBank(bank);
      }
    }

    // Слушаем изменения значений в форме и сохраняем их в локальное хранилище
    this.filterForm.valueChanges.subscribe((value) => {
      if (!this.appService.isArmApplication)
        this.formFilterService.saveFilterFormToLocalStorage();
    });
  }

  // Обработка параметров из url
  public getParamsFromUrl(): void {
    this.route.queryParams.subscribe(params => {
      const {
        mortgagePropertyType,
        creditRemain,
        insuredBirthDate,
        insuredGender,
        product,
      } = this.filterForm.controls;

      const {
        InsurerProductName,
        CreditRemain,
        InsuredBirthDate,
        InsuredGender,
        ContractNumber,
        MortgageStartDate
      } = params;

      if (InsurerProductName) product.setValue(InsurerProductName);
      if (InsuredBirthDate) insuredBirthDate.setValue(InsuredBirthDate);
      if (InsuredGender) insuredGender.setValue(InsuredGender);
      if (CreditRemain) creditRemain.setValue(CreditRemain.toString().replace('.', ','));
      if (ContractNumber) (this.formService.form.get('mortgage') as FormGroup)?.get('mortgageCreditNumber')?.setValue(ContractNumber);
      if (MortgageStartDate) (this.formService.form.get('mortgage') as FormGroup)?.get('mortgageBeginDate')?.setValue(MortgageStartDate);

      // Если форма валидна, то сразу делаем предрасчет иначе подсвечиваем невалидные поля
      if (InsurerProductName && InsuredBirthDate && InsuredGender && CreditRemain && ContractNumber && MortgageStartDate) {
        if (this.filterForm.valid) {
          this.calculate();
        } else {
          this.filterForm.markAllAsTouched();
        }
      }

    });

  }

  // Выбираем банк по умолчанию Сбер
  public setDefaultBank(): void {
    const {
      bankId
    } = this.filterForm.controls;

    // Добавляем деффолтный банк - Сбер
    if (this.partnerBankId) {
      bankId.setValue(this.partnerBankId);
    }

    const bank = this.formFilterService.allMortgageProductList.find((bank) => {
      return bank.bankId === bankId.value
    });

    if (bank) {
      this.selectBank(bank);
    }
  }

  private setProductType(): void {
    const productId = this.formFilterService.form.get('productId')?.value;
    const findProduct = this.formFilterService.allMortgageProductList.find((product: any) => product.productId === productId);
    if (findProduct?.productType) {
      this.formFilterService.form.get('product')?.setValue(findProduct?.productType);
    }
  }

  public showPdfDocModal(src: string): void {
    const initialState = {
      src,
    };
    this.modalService.bsModalRef = this.bsModalService.show(PdfDocViewComponent, {
      initialState,
      class: 'modal-dialog-centered PDFmodal'
    });

  }

  protected readonly environment = environment;
}
