<div class="card mt-4 border-light h-auto shadow-sm rounded-5 offer">
  <div class="card-body p-4">
    <div class="row align-items-center justify-content-between">
      <div class="col-12 col-sm-auto mb-2 mb-sm-0">
        <div class="m-0 d-flex align-items-center">
          <img src="./assets/images/partner-logo/{{offer.insurerType}}-logo.svg"
               class="rounded d-inline-block" alt="{{offer.insurerName}}">
          <div>
            <div class="h6 m-0 p-2 pt-1 pb-0"><strong>Ожидание ответа от страховой</strong></div>
            <div class="m-0 p-2 pt-1 pb-0 text-secondary">Компания осуществляет проверку данных</div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-auto text-end">
        <button type="button" class="btn btn-primary btn-lg w-100 d-flex justify-content-center align-items-center"
                [disabled]="true">
          Создаем полис
          {{ paymentCountdown }}
        </button>
      </div>
    </div>
  </div>
</div>
