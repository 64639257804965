import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-loader-offer',
  templateUrl: './loader-offer.component.html',
  styleUrls: ['./loader-offer.component.scss']
})
export class LoaderOfferComponent implements OnInit {

  @Input() offer: any;

  constructor() {
  }

  public isPaymentCountdownStarted = false;
  public paymentCountdown = '02:30';
  private countdownInterval: any;

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
    this.startPaymentCountdown();
  }
  // --------------------------------------------------------------------------

  // Счетчик
  public startPaymentCountdown() {
    this.isPaymentCountdownStarted = true;

    // начинаем обратный отсчет
    this.countdownInterval = setInterval(() => {
      const timeParts = this.paymentCountdown.split(':');
      let minutes = +timeParts[0];
      let seconds = +timeParts[1];

      if (minutes === 0 && seconds === 0) {
        clearInterval(this.countdownInterval);
        this.isPaymentCountdownStarted = false;
        return;
      }

      if (seconds === 0) {
        minutes--;
        seconds = 59;
      } else {
        seconds--;
      }

      this.paymentCountdown = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }, 1000);
  }
}
