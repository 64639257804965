import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";
import {Subscription} from "rxjs";
import {FormService} from "../../../../shared/services/form.service";
import moment from "moment-mini";
import {MortgageService} from "../../../../shared/services/mortgage.service";
import {YandexMetrikaService} from "../../../../shared/services/yandex-metrika.service";
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-policy-date-form',
  templateUrl: './plicy-date-form.component.html',
  styleUrls: ['./plicy-date-form.component.scss']
})
export class PlicyDateFormComponent implements OnInit, OnDestroy {
  // Событие заполненной формы даты полиса
  @Output() policyFormComplete: EventEmitter<boolean> = new EventEmitter<boolean>();
  // Форма даты полиса заблочена
  @Input() isPolicyFormDisabled!: boolean;
  // Уже искали офферов, значит форма заполнена и теперь толкьо сохраняем ее без того что бы идти дальше по степам
  @Input() isApproveOffersComplete!: boolean;

  constructor(public readonly formService: FormService,
              private readonly mortgageService: MortgageService,
              private readonly ym: YandexMetrikaService) {
  }

  public bankSystemName = environment.partnerSystemName;

  // Форма контактов
  public formPolicy = (this.formService.form.get('mortgagePolicyDate') as UntypedFormGroup);
  // Подписка
  private _subscription = new Subscription();
  // Индикатор загрузки
  public isLoading = false;

  // Доступен для редактирования контрол - Дата окончания страхования
  get isReadonlyMortgageEndDate(): boolean {
    const selectedBank = this.mortgageService.selectedBank;
    return selectedBank && selectedBank.bankSystemName !== 'Vtb' && selectedBank.bankSystemName !== 'Gazprombank'
  }

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
    this.subscribeForm();
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  // --------------------------------------------------------------------------

  // Заполнили форму контактов
  public onSubmit(isSaveForm: boolean): void {
    this.formPolicy.markAllAsTouched();
    if (this.formPolicy.invalid) return;

    this.formService.isPolicyFormComplete = true;

    this.ym.onYandexReachGoal('POLICE_DATE_GOAL');

    this.policyFormComplete.emit(isSaveForm);
  }

  // Подписка на изменение формы
  private subscribeForm(): void {
    const {
      mortgageStartDate,
      mortgageEndDate
    } = this.formPolicy.controls;

    const selectedBank = this.mortgageService.selectedBank;

    if (selectedBank && selectedBank.bankSystemName !== 'Vtb' && selectedBank.bankSystemName !== 'Gazprombank') {
      this._subscription.add(
        mortgageStartDate.valueChanges
          .subscribe((value) => {
            // Дата окончания договора всегда + 1 год
            if (value && value.length > 0 && value.toString().length === 8) {
              mortgageEndDate.setValue(
                moment(this.mortgageService.getFormatDate(value),
                  'DD.MM.YYYY', true)
                  .add(1, 'year')
                  .subtract(1, 'day')
                  .format('DD.MM.YYYY')
              )
            }
          })
      )
    }

  }


}
