<div class="card mt-2 mt-sm-4 border-light h-auto shadow-sm rounded-4">
  <div class="card-body p-2 p-sm-4">
    <div class="row card-form-block">
      <div class="col-auto card-form-block-icon">
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="48" height="48" rx="16" fill="#F2F4F7"/>
          <g clip-path="url(#clip0_220_5435)">
            <path
              d="M35.121 21.0691L27.536 13.4831C26.5973 12.5471 25.3257 12.0215 24 12.0215C22.6744 12.0215 21.4028 12.5471 20.464 13.4831L12.879 21.0691C12.5994 21.3469 12.3778 21.6774 12.2269 22.0415C12.076 22.4056 11.9989 22.796 12 23.1901V33.0071C12 33.8028 12.3161 34.5658 12.8787 35.1284C13.4413 35.691 14.2044 36.0071 15 36.0071H33C33.7957 36.0071 34.5587 35.691 35.1213 35.1284C35.6839 34.5658 36 33.8028 36 33.0071V23.1901C36.0011 22.796 35.924 22.4056 35.7731 22.0415C35.6222 21.6774 35.4006 21.3469 35.121 21.0691ZM27 34.0071H21V30.0731C21 29.2775 21.3161 28.5144 21.8787 27.9518C22.4413 27.3892 23.2044 27.0731 24 27.0731C24.7957 27.0731 25.5587 27.3892 26.1213 27.9518C26.6839 28.5144 27 29.2775 27 30.0731V34.0071ZM34 33.0071C34 33.2723 33.8947 33.5267 33.7071 33.7142C33.5196 33.9018 33.2652 34.0071 33 34.0071H29V30.0731C29 28.747 28.4732 27.4753 27.5355 26.5376C26.5979 25.5999 25.3261 25.0731 24 25.0731C22.6739 25.0731 21.4022 25.5999 20.4645 26.5376C19.5268 27.4753 19 28.747 19 30.0731V34.0071H15C14.7348 34.0071 14.4804 33.9018 14.2929 33.7142C14.1054 33.5267 14 33.2723 14 33.0071V23.1901C14.0009 22.9251 14.1062 22.6711 14.293 22.4831L21.878 14.9001C22.4417 14.339 23.2047 14.0241 24 14.0241C24.7953 14.0241 25.5583 14.339 26.122 14.9001L33.707 22.4861C33.8931 22.6734 33.9983 22.9261 34 23.1901V33.0071Z"
              fill="#626F80"/>
          </g>
          <defs>
            <clipPath id="clip0_220_5435">
              <rect width="24" height="24" fill="white" transform="translate(12 12)"/>
            </clipPath>
          </defs>
        </svg>
      </div>
      <div class="col-7 card-form-block-title p-0">
        <div class="fw-bold" [hidden]="formService.isInsuranceFormComplete">Жильё</div>
        <div class="text-secondary " [hidden]="formService.isInsuranceFormComplete">Которое оформлено в ипотеку</div>
        <div class="fw-bold" [hidden]="!formService.isInsuranceFormComplete">Жильё</div>
        <div class="text-secondary " [hidden]="!formService.isInsuranceFormComplete">Которое оформлено в ипотеку</div>
      </div>
      <div class="col" *ngIf="!formService.isInsuranceFormDisabled" [hidden]="!formService.isInsuranceFormComplete">
        <div class="d-flex text-end justify-content-end">
          <button type="button" class="btn btn-outline-primary btn-lg d-none d-sm-block"
                  (click)="formService.isInsuranceFormComplete = !formService.isInsuranceFormComplete">Редактировать
          </button>
          <button type="button" class="btn btn-outline-light p-2 btn-link d-block d-sm-none"
                  (click)="formService.isInsuranceFormComplete = !formService.isInsuranceFormComplete">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_670_507)">
                <path
                  d="M19.0442 0.956628C18.4776 0.390959 17.7098 0.0732422 16.9092 0.0732422C16.1086 0.0732422 15.3407 0.390959 14.7742 0.956628L1.22085 14.51C0.832722 14.8959 0.524981 15.355 0.315428 15.8606C0.105874 16.3663 -0.00133165 16.9084 1.24844e-05 17.4558V19.1666C1.24844e-05 19.3876 0.0878099 19.5996 0.24409 19.7559C0.40037 19.9122 0.612332 20 0.833346 20H2.54418C3.09148 20.0015 3.63365 19.8945 4.13931 19.6851C4.64496 19.4756 5.10406 19.168 5.49001 18.78L19.0442 5.22579C19.6096 4.65929 19.9271 3.8916 19.9271 3.09121C19.9271 2.29082 19.6096 1.52313 19.0442 0.956628ZM4.31168 17.6016C3.84168 18.0685 3.20665 18.3314 2.54418 18.3333H1.66668V17.4558C1.66584 17.1274 1.73014 16.8021 1.85588 16.4987C1.98161 16.1953 2.16628 15.9198 2.39918 15.6883L12.685 5.40246L14.6017 7.31913L4.31168 17.6016ZM17.865 4.04746L15.7767 6.13663L13.86 4.22413L15.9492 2.13496C16.075 2.00938 16.2244 1.90983 16.3887 1.84197C16.553 1.77411 16.7291 1.73929 16.9069 1.73948C17.0847 1.73967 17.2607 1.77488 17.4249 1.8431C17.5891 1.91131 17.7382 2.01119 17.8638 2.13704C17.9893 2.26289 18.0889 2.41225 18.1568 2.57657C18.2246 2.7409 18.2594 2.91698 18.2592 3.09477C18.2591 3.27255 18.2238 3.44856 18.1556 3.61274C18.0874 3.77692 17.9875 3.92605 17.8617 4.05163L17.865 4.04746Z"
                  fill="#98A2B3"/>
              </g>
              <defs>
                <clipPath id="clip0_670_507">
                  <rect width="20" height="20" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
      </div>
    </div>


    <div [hidden]="formService.isInsuranceFormComplete">
      <form [formGroup]="formInsuranceObject" novalidate>

        <div class="row mt-4">
          <div class="col-12 col-md-12 col-lg-8 mb-4 address-same-insurer">
            <div class="form-check form-switch form-check-reverse"
                 [tooltip]="this.formInsuranceObject.get('insuranceObjectAddressSameAsInsurer')?.disabled ? 'В адресе заемщика не указан номер квартиры, по этому он не может быть использован как адрес квартиры страхователя' : ''">
              <input class="form-check-input" type="checkbox" id="withoutCreditNumber"
                     formControlName="insuranceObjectAddressSameAsInsurer">
              <label class="form-check-label" for="withoutCreditNumber">
                <div>Адрес квартиры совпадает с адресом регистрации заёмщика</div>
              </label>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="formInsuranceObject.get('insuranceObjectAddress')?.enabled">
          <div class="col-12 col-md-8 mb-4">
            <app-autocomplete
              formControlName="insuranceObjectAddress"
              [daDataControlName]="'insuranceObjectAddressDaData'"
              [suggestionPart]="daDataSystemName.ADDRESS"
              [isReadonly]="isLoading"></app-autocomplete>
          </div>
          <div class="col-12 col-md-4 mb-4">
            <app-form-number-field formControlName="insuranceHouseNumber"
                                   [isReadonly]="isLoading"></app-form-number-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6 col-lg-4 mb-4" *ngIf="formInsuranceObject.get('withWood')?.enabled">
            <div class="form-check form-switch form-check-reverse">
              <input class="form-check-input" type="checkbox" id="withWood" formControlName="withWood">
              <label class="form-check-label" for="withWood">Есть деревянные перекрытия</label>
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-4 mb-4" *ngIf="formInsuranceObject.get('hasGas')?.enabled">
            <div class="form-check form-switch form-check-reverse">
              <input class="form-check-input" type="checkbox" id="hasGas" formControlName="hasGas">
              <label class="form-check-label" for="hasGas">Есть газ</label>
            </div>
          </div>


          <div class="col-12 col-md-6 col-lg-4 mb-4"
               *ngIf="formInsuranceObject.get('insuranceObjectBuiltYear')?.enabled">
            <app-form-number-field formControlName="insuranceObjectBuiltYear"
                                   [isReadonly]="isLoading"></app-form-number-field>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mb-4"
               *ngIf="formInsuranceObject.get('insuranceObjectSquare')?.enabled"
               [hidden]="formService.insuranceObjectSquareIsHidden">
            <app-form-number-field formControlName="insuranceObjectSquare"
                                   [isReadonly]="isLoading"></app-form-number-field>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mb-4"
               *ngIf="formInsuranceObject.get('insuranceBaseAmount')?.enabled">
            <app-form-number-field formControlName="insuranceBaseAmount"
                                   [isReadonly]="isLoading"></app-form-number-field>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mb-4" *ngIf="formInsuranceObject.get('kadastr')?.enabled">
            <app-form-text-field formControlName="kadastr" [isReadonly]="isLoading"></app-form-text-field>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mb-4" *ngIf="formInsuranceObject.get('floorCount')?.enabled">
            <app-form-number-field formControlName="floorCount" [isReadonly]="isLoading"></app-form-number-field>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mb-4" *ngIf="formInsuranceObject.get('roomsCount')?.enabled">
            <app-form-number-field formControlName="roomsCount" [isReadonly]="isLoading"></app-form-number-field>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mb-4" *ngIf="formInsuranceObject.get('floor')?.enabled">
            <app-form-number-field formControlName="floor" [isReadonly]="isLoading"></app-form-number-field>
          </div>


          <div class="col-12 col-md-6 col-lg-4 mb-4"
               *ngIf="formInsuranceObject.get('ownershipLessThreeYears')?.enabled">
            <div class="form-check form-switch form-check-reverse">
              <input class="form-check-input" type="checkbox" id="ownershipLessThreeYears"
                     formControlName="ownershipLessThreeYears">
              <label class="form-check-label" for="ownershipLessThreeYears">Во владении меньше 3х лет</label>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mb-4" *ngIf="formInsuranceObject.get('ownershipRestrictDoc')?.enabled">
            <div class="form-check form-switch form-check-reverse">
              <input class="form-check-input" type="checkbox" id="ownershipRestrictDoc"
                     formControlName="ownershipRestrictDoc">
              <label class="form-check-label" for="ownershipRestrictDoc">Документ, подтверждающий ограничение права собственности</label>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mb-4" *ngIf="formInsuranceObject.get('monthlyPayment')?.enabled">
            <app-form-number-field formControlName="monthlyPayment" [isReadonly]="isLoading"></app-form-number-field>
          </div>
        </div>

      </form>

      <form [formGroup]="form" novalidate>
        <div class="row">
          <div class="col-12 mb-4">
            <div class="form-check">
              <input class="form-check-input"
                     [ngClass]="{
                      'is-invalid': form.get('privacy')?.invalid
                     }"
                     type="checkbox" formControlName="privacy" id="flexCheckPrivacy" checked>
              <label class="form-check-label" for="flexCheckPrivacy">
                Согласен с&nbsp;
              </label>
              <a href="javascript:void(0)" (click)="showPdfDocModal('/assets/files/privacy.pdf')">правилами
                предоставления информации</a>
            </div>
          </div>
        </div>
      </form>

      <div class="row text-end justify-content-end">
        <div class="col text-end" *ngIf="!isApproveOffersComplete">
          <button type="button" class="btn btn-primary btn-lg" [disabled]="form.get('privacy')?.invalid"
                  (click)="onSubmit(true)">Продолжить
          </button>
        </div>
        <div class="col-auto text-end" *ngIf="isApproveOffersComplete">
          <button type="button" class="btn btn-outline-secondary btn-lg"
                  (click)="formService.isInsuranceFormComplete = !formService.isInsuranceFormComplete">Отмена
          </button>
        </div>
        <div class="col-auto text-end" *ngIf="isApproveOffersComplete">
          <button type="button" class="btn btn-primary btn-lg" [disabled]="form.get('privacy')?.invalid"
                  (click)="onSubmit(true)">Сохранить
          </button>
        </div>
      </div>
    </div>

  </div>
</div>
