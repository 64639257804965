import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormGroup, UntypedFormGroup} from "@angular/forms";
import {EMPTY, Subject, Subscription} from "rxjs";

// Сервисы
import {FormService} from "../../../../shared/services/form.service";
import {YandexMetrikaService} from "../../../../shared/services/yandex-metrika.service";

// Перечисления
import {DaData} from "../../../../shared/enums/da-data.enum";
import {DadataService} from "../../../../core/services/dadata.service";
import {debounceTime, switchMap, takeUntil} from "rxjs/operators";
import {checkIsHiddenBaseAmount} from "../../../../shared/functions/checkIsHiddenBaseAmount";
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-borrower-form',
  templateUrl: './borrower-form.component.html',
  styleUrls: ['./borrower-form.component.scss']
})
export class BorrowerFormComponent implements OnInit, OnDestroy {

  // Событие заполненной формы контактов
  @Output() borrowerFormComplete: EventEmitter<boolean> = new EventEmitter<boolean>();
  // Форма заемщика заблочена
  @Input() isBorrowerFormDisabled!: boolean;
  // Уже искали офферов, значит форма заполнена и теперь толкьо сохраняем ее без того что бы идти дальше по степам
  @Input() isApproveOffersComplete!: boolean;

  constructor(
    public readonly formService: FormService,
    private readonly ym: YandexMetrikaService,
    private readonly dadataService: DadataService) {
  }

  public bankSystemName = environment.partnerSystemName;
  // Форма контактов
  public formBorrower = (this.formService.form.get('borrower') as UntypedFormGroup);
  // Подписка
  private _subscription = new Subscription();
  // Индикатор загрузки
  public isLoading = false;
  // DaData системные имена
  public daDataSystemName = DaData;
  private destroy$ = new Subject<void>();

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
    this.subscribeHouseNumber()
  }
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this._subscription.unsubscribe();
  }
  // --------------------------------------------------------------------------

  // Заполнили форму заемщика
  public onSubmit(isSaveForm: boolean): void {
    this.formBorrower.markAllAsTouched();
    if (this.formBorrower.invalid) return;

    this.formService.isBorrowerFormComplete = true;

    this.ym.onYandexReachGoal('BORROWER_GOAL');

    this.borrowerFormComplete.emit(isSaveForm);
  }

  // Выбрали значение "Кем выдан паспорт"
  public changeBorrowerPassportIssuer(event: any): void {
    if (event?.item?.data?.code) {
      this.formBorrower.get('borrowerDepartmentCode')?.setValue(event?.item?.data?.code);
    }
  }

  // Выбрали значение "Код подразделения"
  public changeBorrowerDepartmentCode(event: any): void {
    if (event?.item?.data?.name) {
      this.formBorrower.get('borrowerPassportIssuer')?.setValue(event?.item?.data?.name);
    }
    if (event?.item?.data?.code) {
      this.formBorrower.get('borrowerDepartmentCode')?.setValue(event?.item?.data?.code);
    }
  }

  // Подписка на контролы формы
  private subscribeHouseNumber(): void {
    const {
      borrowerAddress,
      borrowerHouseNumber,
      borrowerAddressFullDaData
    } = this.formBorrower.controls;

    const insuranceObjectFormGroup: FormGroup = this.formService.form.get('insuranceObject') as FormGroup;
    const {
      insuranceObjectAddressSameAsInsurer,
    } = insuranceObjectFormGroup.controls;

    this._subscription.add(
      borrowerAddress.valueChanges.pipe(
        debounceTime(300),
        switchMap((value) => {
          if (borrowerHouseNumber.value) {
            return this.dadataService.suggestionAddress(
              value + ', кв ' + borrowerHouseNumber.value,
              DaData.ADDRESS_FULL
            ).pipe(
              takeUntil(this.destroy$) // Отмена запроса при уничтожении subject
            );
          } else {
            // Вернуть EMPTY, если borrowerHouseNumber.value пусто
            return EMPTY;
          }
        })
      ).subscribe((res) => {
        const daData = {value: res[0]?.value, ...res[0]?.data};
        borrowerAddressFullDaData.setValue(daData);
        this.changeInsuranceObjectAddress(daData);
      })
    );

    this._subscription.add(
      borrowerHouseNumber.valueChanges.pipe(
        debounceTime(300),
        switchMap((value) => {

          value ? insuranceObjectAddressSameAsInsurer.enable() : insuranceObjectAddressSameAsInsurer.disable();
          if (!value) {
            insuranceObjectAddressSameAsInsurer.setValue(false);
          } else {
            insuranceObjectAddressSameAsInsurer.setValue(true);
          }

          if (borrowerAddress.value) {
            return this.dadataService.suggestionAddress(
              borrowerAddress.value  + ', кв ' + value,
              DaData.ADDRESS_FULL
            ).pipe(
              takeUntil(this.destroy$)
            );
          } else {
            // Вернуть EMPTY, если borrowerHouseNumber.value пусто
            return EMPTY;
          }

        })
      ).subscribe((res) => {
        const daData = {value: res[0]?.value, ...res[0]?.data};
        borrowerAddressFullDaData.setValue(daData);
        this.changeInsuranceObjectAddress(daData);
      })
    );
  }

  // Выбрали адрес квартиры
  public changeInsuranceObjectAddress(data: any): void {
    const insuranceObjectFormGroup: FormGroup = this.formService.form.get('insuranceObject') as FormGroup;

    const {
      insuranceBaseAmount,
      kadastr,
      insuranceObjectSquare,
      insuranceObjectAddressSameAsInsurer,
    } = insuranceObjectFormGroup.controls;

    this.formService.insuranceObjectSquareIsHidden = data?.flat_area !== null;
    this.formService.insuranceBaseAmountIsHidden = checkIsHiddenBaseAmount(data);

    if (insuranceObjectAddressSameAsInsurer.value) {
      if (data?.flat_cadnum) {
        kadastr.setValue(data?.flat_cadnum);
        this.formService.kadastrIsHidden = false;
      } else {
        // kadastr.reset();
        this.formService.kadastrIsHidden = true;
      }

      if (data?.flat_area) {
        const flatArea = data?.flat_area;
        insuranceObjectSquare.setValue(flatArea);
        if (data?.square_meter_price) {
          const price = Number((data?.flat_area * data?.square_meter_price).toString().replace(',', '.')).toLocaleString('ru-RU', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
            useGrouping: true
          });
          insuranceBaseAmount.setValue(data.flat_price ? data.flat_price : price.replace(/\s/g, ''));
        }
      } else {
        // insuranceObjectSquare.reset();
        // insuranceBaseAmount.reset();
      }
    }
  }

}
