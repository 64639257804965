export const environmentProd = {
    production: true,
    mock: false,
    routerLogging: false,
    hostUrl: 'https://api.insapp.pro/',
    apiUrl: 'https://api.insapp.pro/',
    carInfoApi: 'https://api.insapp.pro/',
    dadataKey: '6e1e70327c0437a3d0d34fe74fd68a767c32d0d4',
    armHost: 'arm.insapp.ru',
    yandexMetrika: 94047660,
    yandexMetrikaPartner: 0
};
