import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MortgageModule} from "./modules/mortgage/mortgage.module";
import {SharedModule} from "./shared/shared.module";
import {CoreModule} from "./core/core.module";
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {SwiperModule} from "swiper/angular";
import {PopoverModule} from "ngx-bootstrap/popover";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {HttpErrorInterceptor} from "./shared/services/httpIntercepter.service";
import {GlobalErrorHandler} from "./shared/services/globalErrorHandler.service";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CoreModule,
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    MortgageModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    SwiperModule,
    PopoverModule.forRoot(),
  ],
  providers: [{
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule {
}
