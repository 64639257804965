import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {MortgageService} from "../../services/mortgage.service";
import {Router} from "@angular/router";
import {concatMap, Subscription} from "rxjs";
import {IOffer} from "../../interface/offers.interface";
import {AppService} from '../../../core/services/app.service';
import {NavigationService} from '../../services/navigation.service';
import {FormService} from "../../services/form.service";
import {FormFilterService} from "../../services/form-filter.service";
import {environment} from "@environment/environment";
import {YandexMetrikaService} from "../../services/yandex-metrika.service";
import {PdfDocViewComponent} from "../pdf-doc-view/pdf-doc-view.component";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-offer-item',
  templateUrl: './offer-item.component.html',
  styleUrls: ['./offer-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OfferItemComponent implements OnInit, AfterViewInit, OnDestroy {

  // Данные оффера
  @Input() offer: any;
  // Если ищем офферов которые можно оплатить
  @Input() isApproveOffer: boolean = false;
  // Если повторно выбираем оффера после того как предыдущий отказал
  @Input() isRepeatOffers: boolean = false;
  // Остаток по кредиту
  @Input() creditRemain: string = this.formFilterService.form.get('creditRemain')?.value;
  @Output() triggerNewGetOffers: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(public readonly mortgageService: MortgageService,
              private readonly appService: AppService,
              public readonly formService: FormService,
              public readonly formFilterService: FormFilterService,
              private readonly navigationService: NavigationService,
              private readonly router: Router,
              private readonly cd: ChangeDetectorRef,
              private readonly ym: YandexMetrikaService,
              private readonly modalService: BsModalService,) {
  }

  public isPaymentCountdownStarted = false;
  public paymentCountdown = '02:00';
  private countdownInterval: any;
  // Документ
  public agreement = true;

  // Системное имя партнера
  public partnerSystemName = environment.partnerSystemName;

  // Индикатор загрузки черновика
  public isDraftLoader = false;
  // Ссылка на модальное окно
  private bsModalRef?: BsModalRef;
  // Показываем ошибку получения ссылки на оплату
  public paymentLinkIsEmpty = false;
  // Показать подарки
  public isShowPromocodes = false;


  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit() {
    if (this.isApproveOffer) {
      this.startPaymentCountdown();
      this.getMortgagePaymentLink(this.offer.offerId);
    }
  }

  public ngAfterViewInit() {

  }

  // Уничтожение
  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  // --------------------------------------------------------------------------

  // Счетчик
  public countdown = 120;
  // Подписка
  private subscriptions: Subscription[] = [];


  get getPreapprovePremiumAmount(): string {
    return this.offer.status === 'PreApproved'
      ? Math.floor(this.offer.preapprovePremiumAmount).toLocaleString('ru-RU', {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
        useGrouping: true
      })
      : Math.floor(this.offer.premiumAmount).toLocaleString('ru-RU', {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
        useGrouping: true
      });
  }

  get getCreditRemainAmount(): string {
    return Math.floor(Number(this.creditRemain.toString().replace(',', '.'))).toLocaleString('ru-RU', {
      maximumFractionDigits: 0, // Устанавливаем максимальное количество дробных знаков в 0
      minimumFractionDigits: 0, // Устанавливаем минимальное количество дробных знаков в 0
      useGrouping: true // Используем группировку разрядов
    });
  }

  // Выбрали оффера
  public selectOffer(offer: IOffer): void {
    if (this.isRepeatOffers) {
      this.mortgageService.selectedOffer = this.offer;
      this.triggerNewGetOffers.emit(true)
    } else if (offer) {
      this.subscriptions.push(
        this.mortgageService.selectOffer(offer.offerId, offer.productId)
          .subscribe(() => {
            this.mortgageService.selectedOffer = this.offer;
            // Сначала активна форма контактов и далее проходимся по всей форме
            this.formService.isContactFormComplete = false;

            this.formService.isBorrowerFormDisabled= true;
            this.formService.isMortgageFormDisabled = true;
            this.formService.isPolicyFormDisabled = true;
            this.formService.isInsuranceFormDisabled = true;

            this.formService.form.markAsUntouched();

            this.ym.onYandexReachGoal('SELECT_OFFER_GOAL');

            if (this.appService.srcUrlParam === 'OffersRecivedEmail') {
              this.router.navigate(['form'], {
                queryParamsHandling: 'merge'
              });
            } else {
              this.router.navigate(['form'], {
                queryParamsHandling: 'merge',
                queryParams: {applicationId: this.appService.applicationId}
              });
            }
          })
      );
    }
  }

  // Получаем ссылку на оплату
  public getMortgagePaymentLink(offerId: string): void {
    this.paymentLinkIsEmpty = false;
    this.subscriptions.push(
      this.mortgageService.calculateAprove()
        .pipe(
          concatMap((res) => {
            this.isDraftLoader = false;
            const offer = res.value.offers.find((offer: any) => offer.offerId === this.mortgageService.selectedOffer?.offerId);

            if (offer?.draft?.status === 2 && offer?.draft?.policyDraft) {
              this.mortgageService.draftLink = offer?.draft?.policyDraft;
              this.mortgageService.additionalPolicyDraft = offer?.draft?.additionalPolicyDraft;
            }
            this.cd.detectChanges();
            return this.mortgageService.getMortgagePaymentLink(offerId)
          })
        )
        .subscribe({
          next: (res) => {
            if(res.result) {
              this.mortgageService.paymentLink = res.value?.mortgagePaymentLink;
              this.paymentLinkIsEmpty = false;
            } else {
              this.paymentLinkIsEmpty = true;
            }

            this.isPaymentCountdownStarted = false;
            this.paymentCountdown = '02:00';
            clearInterval(this.countdownInterval);
            this.ym.onYandexReachGoal('GET_PAYMENT_LINK_GOAL');
            // Вставляем событие клика для ссылок на документы
            this.setClickForDocs();
            // Делаем проверку на изменение dom
            this.cd.markForCheck();
          },
          error: () => {},
          complete: () => {
            if (this.appService.isArmApplication)
              this.navigationService.navigate('/success');
          }
        })
    );
  }

  // Оплатить
  public payment(): void {
    this.subscriptions.push(this.mortgageService.clickPay().subscribe());
    window.open(this.mortgageService.paymentLink, '_blank');
  }

  // Открыть черновик
  public openDraft(): void {
    const initialState = {
      src: environment.apiUrl + 'File/draft/' + this.mortgageService.draftLink,
    };
    this.bsModalRef = this.modalService.show(PdfDocViewComponent, {
      initialState,
      class: 'modal-dialog-centered PDFmodal'
    });
  }

  // Открыть черновик
  public openAdditionalDraft(): void {
    const initialState = {
      src: environment.apiUrl + 'File/draft/' + this.mortgageService.additionalPolicyDraft,
    };
    this.bsModalRef = this.modalService.show(PdfDocViewComponent, {
      initialState,
      class: 'modal-dialog-centered PDFmodal'
    });
  }

  // Счетчик
  public startPaymentCountdown() {
    this.isPaymentCountdownStarted = true;

    // начинаем обратный отсчет
    this.countdownInterval = setInterval(() => {
      const timeParts = this.paymentCountdown.split(':');
      let minutes = +timeParts[0];
      let seconds = +timeParts[1];

      if (minutes === 0 && seconds === 0) {
        clearInterval(this.countdownInterval);
        this.isPaymentCountdownStarted = false;
        // Вставляем событие клика для ссылок на документы
        this.setClickForDocs();
        return;
      }

      if (seconds === 0) {
        minutes--;
        seconds = 59;
      } else {
        seconds--;
      }

      this.paymentLinkIsEmpty = false;
      this.cd.detectChanges();

      this.paymentCountdown = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }, 1000);
  }

  // Вставляем событие клика для ссылок на документы
  public setClickForDocs(): void {
    setTimeout(() => {
      // В этом методе контент [innerHTML]="item" уже доступен
      const elements = document.querySelectorAll('.form-check-label a');
      elements.forEach((element: any) => {
        element.addEventListener('click', (event: Event) => {
          event.preventDefault();

          if (!element.data) {
            element.data = element.href;
          }

          const initialState = {
            src: element.data,
          };
          this.bsModalRef = this.modalService.show(PdfDocViewComponent, {
            initialState,
            class: 'modal-dialog-centered PDFmodal'
          });

          element.href = 'javascript:void(0)';
          element.target = '_self';
        });

      });
    }, 100)
  }

}
