import {Component, ElementRef, forwardRef, Inject, Injector, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  FormControlDirective,
  FormControlName,
  FormGroupDirective, NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgControl
} from '@angular/forms';
import {Subscription} from 'rxjs';

// Сервисы
import {FormService} from '../../services/form.service';

// Маски
import {MASKS} from '../../constants/masks';

// Интерфейсы
import {IMask} from '../../interface/masks.interface';
import {ValidationService} from '../../services/validation.service';

@Component({
  selector: 'app-form-text-field',
  templateUrl: './form-text-field.component.html',
  styleUrls: ['./form-text-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormTextFieldComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormTextFieldComponent),
      multi: true
    }]
})
export class FormTextFieldComponent implements ControlValueAccessor, OnInit, OnDestroy {

  @ViewChild('textControl') textControl!: ElementRef;

  // Заголовок
  @Input() label!: string;
  // Только для чтения
  @Input() isReadonly!: boolean;
  // Placeholder
  @Input() placeholder!: string;

  // Контрол изменяемого input
  public inputControl: UntypedFormControl = new UntypedFormControl(null);
  // Контрол
  public control!: UntypedFormControl;
  // Имя контрола
  public name!: string;
  // Тип
  public type = 'text';
  // Подписка на контрол
  private subscription: Subscription = new Subscription();
  // Сообщения валидации
  public messages: { [key: string]: any } = {};
  // Флаг, идет загрузка списка
  public isLoading = false;
  // Маска
  public mask: any;
  // Маска для чистого значения
  public clearMask: any;
  // Показываем маску при заполнении
  public showMask = false;
  // Показываем клавиатуру "только цифры" в мобильных устройствах
  public isNumberKeyboard!: boolean;
  // Показываем клавиатуру, текст или только цифры
  public inputmode: string = 'text';
  // Паттерн
  public pattern: string = '';

  // Вызовем когда значение изменится
  public onTouched: any = () => {
  };

  // Вызовем при любом действии пользователя с контроллом
  public onChange: any = () => {
  };

  constructor(@Inject(Injector) private injector: Injector,
              public readonly formService: FormService,
              private readonly validationService: ValidationService) {
  }

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
    const injectedControl = this.injector.get(NgControl);
    this.name = injectedControl.name + 'controlName';

    switch (injectedControl.constructor) {
      case FormControlName: {
        this.control = this.injector.get(FormGroupDirective).getControl(injectedControl as FormControlName);
        break;
      }
      default: {
        this.control = (injectedControl as FormControlDirective).form as UntypedFormControl;
        break;
      }
    }

    // Применяем параметры контрола
    if (injectedControl.name) {
      this.initPropertyControl(injectedControl.name.toString());
    }

    // Подписка на изменение контрола
    this.subscription.add(
      this.inputControl.valueChanges.subscribe((value) => {
        this.onChange(value);
      })
    );
  }

  // Уничтожение
  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  // --------------------------------------------------------------------------

  // Применяем параметры контролла
  public initPropertyControl(injectedControlName: string): void {
    const propertyControl = this.formService.propertyControls[injectedControlName];
    if (propertyControl?.validation?.messages) {
      this.messages = propertyControl?.validation?.messages;
    }
    if (propertyControl?.label) {
      this.label = propertyControl.label;
    }
    if (propertyControl?.mask) {
      MASKS.filter((item: IMask) => item.systemName === propertyControl?.mask)
        .map((item) => this.mask = item.mask);
    }
    if (propertyControl?.showMask) {
      this.showMask = propertyControl?.showMask;
    }
    if (propertyControl?.type) {
      this.type = propertyControl?.type;
    }
    if (propertyControl?.placeholder) {
      this.placeholder = propertyControl?.placeholder;
    }
    if (propertyControl?.inputmode) {
      this.inputmode = propertyControl?.inputmode;
    }
    if (propertyControl?.pattern) {
      this.pattern = propertyControl?.pattern;
    }
    if (propertyControl?.isReadonly) {
      this.isReadonly = propertyControl.isReadonly;
    }

    this.validationService.setControlValidation(propertyControl, this.control);
    this.validationService.setControlValidation(propertyControl, this.inputControl);

    return;
  }

  // Вызовет форма, если значение изменилось извне
  public writeValue(value: any): void {
    value ? this.inputControl.setValue(value) : this.inputControl.setValue('');
  }

  // Сохраняем обратный вызов для изменений
  registerOnChange(onChange: (value: any) => void): void {
    this.onChange = onChange;
  }

  // Сохраняем обратный вызов для "касаний"
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Установка состояния disabled
  public setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.inputControl.disable() : this.inputControl.enable();
  }

  // Событие при выходи из сонктрола
  public blurControl(event: Event): void {
    this.control.markAsTouched();
  }

  public validate(c: UntypedFormControl): any {
    if (!this.inputControl) {
      return null;
    }
    // return this.inputControl.type === this.type ? null : {
    //     type: {
    //         valid: false,
    //         actual: c.value
    //     }
    // }
  }

  // Событие фокуса
  public focus(event: any): void {
    if (this.type === 'phone') {
      let digits = event?.target?.value.replace(/\D/g, '');
      const charPositionValue = digits.length === 1
        ? digits.length + 1
        : digits.length >= 2 && digits.length <= 4
          ? digits.length + 2
          : digits.length >= 5 && digits.length <= 7
            ? digits.length + 4
            : digits.length >= 8 && digits.length <= 9
              ? digits.length + 5
              : digits.length >= 10 && digits.length <= 11
                ? digits.length + 6
                : digits.length;
      setTimeout(() => {
        this.textControl.nativeElement.setSelectionRange(charPositionValue, charPositionValue);
      });
    }

  }

}
