<div class="has-validation" [class.form-floating]="label" *ngIf="!isHidden">
    <input [formControl]="inputControl"
           [ngClass]="{
            'is-invalid': control.invalid && control.touched
           }"
           class="form-control"
           [class.form-control-lg]="large"
           [textMask]="{mask: mask}"
           [placeholder]="label ? label : placeholder"
           [name]="name"
           [readOnly]="isReadonly"
           type="text"
           autocomplete="off"
           [attr.inputmode]="inputmode"
           [pattern]="pattern"
           (input)="onKeyDown($event)"
           (blur)="blurControl($event)">
    <label *ngIf="label">{{label}}</label>
    <app-form-field-error
        *ngIf="control?.invalid && control?.touched"
        [controlErrors]="control.errors"
        [messages]="messages">
    </app-form-field-error>
</div>
