<div [formGroup]="formOffline">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Для оформления полиса оставьте заявку</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <alert type="my-info">
      Для данного продукта необходимо оформление с помощью менеджера
    </alert>
    <alert type="my-info-gray">
      <p>1. В течение часа с вами свяжется наш менеджер для уточнения деталей</p>
      <p>2. После согласования условий, вы получите ссылку на оплату полиса напрямую от страховой компании</p>
      <p>3. Полис поступит на вашу электронную почту сразу же после оплаты</p>
    </alert>

    <div class="row mt-4">
      <!-- 1 -->
      <div class="col-12 col-md-4 col-xxl-4 mb-3">
        <app-autocomplete
          formControlName="offlineFormLastName"
          [suggestionPart]="daDataSystemName.SURNAME"
          [isReadonly]="isLoading"></app-autocomplete>
      </div>
      <!-- 2 -->
      <div class="col-12 col-md-4 col-xxl-4 mb-3">
        <app-autocomplete
          formControlName="offlineFormFirstName"
          [suggestionPart]="daDataSystemName.NAME"
          [isReadonly]="isLoading"></app-autocomplete>
      </div>
      <!-- 3 -->
      <div class="col-12 col-md-4 col-xxl-4 mb-3">
        <app-autocomplete
          formControlName="offlineFormMiddleName"
          [suggestionPart]="daDataSystemName.PATRONYMIC"
          [isReadonly]="isLoading"></app-autocomplete>
      </div>
      <!-- 4 -->
      <div class="col-12 col-md-4 col-xxl-4 mb-4">
        <app-form-text-field formControlName="city" [isReadonly]="isLoading"></app-form-text-field>
      </div>
      <!-- 5 -->
      <div class="col-12 col-md-4 col-xxl-4 mb-4">
        <app-form-text-field formControlName="email" [isReadonly]="isLoading"></app-form-text-field>
      </div>
      <!-- 6 -->
      <div class="col-12 col-md-4 col-xxl-4 mb-4">
        <app-form-text-field formControlName="phone" [isReadonly]="isLoading"></app-form-text-field>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-12 col-sm-auto">
        <button type="button" class="btn btn-outline-secondary btn-lg w-100 mb-2 mb-sm-0" (click)="bsModalRef.hide()">Закрыть</button>
      </div>
      <div class="col-12 col-sm-auto">
        <button type="button" class="btn btn-primary btn-lg w-100" (click)="onSubmit()" [disabled]="isLoading">
          <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Отправить заявку
        </button>
      </div>
    </div>
  </div>

</div>
