import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-progress-circle',
  templateUrl: './progress-circle.component.html',
  styleUrls: ['./progress-circle.component.scss']
})
export class ProgressCircleComponent implements OnChanges {
  @Input() isLoading = false;
  @Input() paymentCountdown = '02:30'; // Время в формате 'MM:SS'

  radius = 36;
  strokeWidth = 5;
  circumference = 2 * Math.PI * this.radius;
  progress = 0;
  countdownTime = 0; // Время в миллисекундах
  remainingTime = 0; // Время, оставшееся в миллисекундах
  intervalId: any;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['paymentCountdown']) {
      this.updateCountdownTime(); // Обновляем время по значению paymentCountdown
    }

    if (changes['isLoading']) {
      if (this.isLoading) {
        this.startTimer();
      } else {
        this.resetTimer();
      }
    }
  }

  // Метод для преобразования 'MM:SS' в миллисекунды
  updateCountdownTime() {
    const [minutes, seconds] = this.paymentCountdown.split(':').map(Number);
    this.countdownTime = (Number(minutes) * 60 + Number(seconds)) * 1000; // Преобразуем в миллисекунды
    this.remainingTime = this.countdownTime;
  }

  startTimer() {
    if (this.countdownTime <= 0) {
      return;
    }

    this.progress = 0; // Сбрасываем прогресс
    const interval = 1000 / 60; // 60 FPS
    const step = (this.circumference / this.countdownTime) * interval;

    this.intervalId = setInterval(() => {
      this.progress += step;
      this.remainingTime -= interval;

      // Обратный отсчет времени
      if (this.remainingTime <= 0) {
        this.progress = this.circumference;
        clearInterval(this.intervalId);
      }

      if (this.progress >= this.circumference) {
        this.progress = this.circumference;
        clearInterval(this.intervalId);
      }
    }, interval);
  }



  resetTimer() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.progress = 0; // Сброс прогресса до 0
    this.paymentCountdown = '02:00'; // Сброс времени
    this.remainingTime = this.countdownTime;
  }

  // Метод для получения стиля dasharray, который зависит от прогресса
  getDashArray() {
    return `${this.progress} ${this.circumference}`;
  }
}
