import {AfterViewInit, Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appIsNumeric]'
})
export class IsNumericDirective implements AfterViewInit {
  @Input() numericMaxLength: number | null = null;
  @Input() decimalPlaces: number | null = null;
  @Input() decimalSeparator: string | null = ',';
  @Input() numericThousandSeparator: string | null = null;
  @Input() postfix: string | null = null;

  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste'
  ];
  inputElement: HTMLElement;

  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  // --------------------------------------------------------------------------
  // Инициализация
  public ngAfterViewInit(): void {
    const elem = this.el.nativeElement;
    const value = this.postfix != null
      ? elem.value.replace(this.postfix, '')
      : elem.value;
    elem.value = this.getValidText(value);
    if (this.postfix != null) {
      const currentPosition = this.getCaretPosition(elem);
      const maxPosition = elem.value.length - this.postfix.length - 1;
      if (currentPosition > maxPosition) {
        this.setCaretPosition(elem, maxPosition);
      }
    }
    elem.blur();
  }

  // --------------------------------------------------------------------------

  @HostListener('keydown', ['$event'])
  onKeyDown(event: any): void {

    if (
      this.navigationKeys.indexOf(event.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
      (event.key === 'a' && event.ctrlKey === true) || // Allow: Ctrl+A
      (event.key === 'c' && event.ctrlKey === true) || // Allow: Ctrl+C
      (event.key === 'v' && event.ctrlKey === true) || // Allow: Ctrl+V
      (event.key === 'x' && event.ctrlKey === true) || // Allow: Ctrl+X
      (event.key === 'a' && event.metaKey === true) || // Allow: Cmd+A (Mac)
      (event.key === 'c' && event.metaKey === true) || // Allow: Cmd+C (Mac)
      (event.key === 'v' && event.metaKey === true) || // Allow: Cmd+V (Mac)
      (event.key === 'x' && event.metaKey === true)    // Allow: Cmd+X (Mac)
    ) {
      return;
    }

    if (this.el.nativeElement.selectionStart >= 0 && this.el.nativeElement.selectionEnd > this.el.nativeElement.selectionStart) {
      return;
    }


    const elem = event.target;
    if (this.isInPostfixPart(elem)) {
      event.preventDefault();
      return;
    }

    const maxlength = this.numericMaxLength || 0;
    if (maxlength > 0 && !this.isInDecimalPart(event.target)) {
      let value = event.target.value || '';
      if (this.postfix != null) {
        value = value.replace(' ' + this.postfix, '');
      }

      if (this.numericThousandSeparator != null && this.numericThousandSeparator.length > 0) {
        value = value.split(this.numericThousandSeparator).join('').replace(/\s/g, '');
      }

      if (value.length >= maxlength) {
        event.preventDefault();
        return;
      }
    }

    if (this.isInDecimalPart(elem) && !this.checkDecimalMaxPlaces(elem)) {
      event.preventDefault();
      return;
    }

    // Убираем все пробелы
    const noSpaces = event.target.value.replace(/\s/g, '');
    // Проверяем есть ли десятичная часть и если да, то обрезаем ее до двух символов
    const match = noSpaces.match(/^(\d+),(\d{0,1}).*₽$/);
    if (match) {
      // Если есть десятичная часть, возвращаем число с двумя знаками после запятой и префиксом
      event.target.value = `${match[1].toLocaleString('ru-RU', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
        useGrouping: true
      })},${match[2]}`;
    } else {
      // Если нет десятичной части, просто возвращаем число с префиксом
      event.target.value = `${noSpaces.toLocaleString('ru-RU', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
        useGrouping: true
      })}`;
    }

    if (
      (event.key === '0') ||
      (event.key === '1') ||
      (event.key === '2') ||
      (event.key === '3') ||
      (event.key === '4') ||
      (event.key === '5') ||
      (event.key === '6') ||
      (event.key === '7') ||
      (event.key === '8') ||
      (event.key === '9') ||
      (event.key === ',')
    ) {
      // let it happen, don't do anything
      return;
    }

    if (event.key === this.decimalSeparator) {
      if ((elem.value || '').replace(this.postfix || '', '').indexOf(this.decimalSeparator) !== -1) {
        event.preventDefault();
      }

      return;
    }

    event.preventDefault();
  }

  @HostListener('input', ['$event'])
  onInput(event: any): void {
    const elem = event.target;
    const value = this.postfix != null
      ? elem.value.replace(this.postfix, '')
      : elem.value;

    elem.value = this.getValidText(value);
    if (this.postfix != null) {
      const currentPosition = this.getCaretPosition(elem);
      const maxPosition = elem.value.length - this.postfix.length - 1;
      if (currentPosition > maxPosition) {
        this.setCaretPosition(elem, maxPosition);
      }
    }
  }

  private getValidText(value: any): string {
    let result = '';
    let index = 0;
    while (index < value.length) {
      switch (value.substr(index, 1)) {
        case '0':
        case '1':
        case '2':
        case '3':
        case '4':
        case '5':
        case '6':
        case '7':
        case '8':
        case '9':
        case ',':
        case this.decimalSeparator:
          result += value.substr(index, 1);
      }

      index++;
    }

    const parts = (this.decimalSeparator != null && this.decimalPlaces != null)
      ? result.split(this.decimalSeparator)
      : [result];

    if (this.numericMaxLength != null && parts[0].length > this.numericMaxLength) {
      parts[0] = parts[0].substr(0, this.numericMaxLength);
    }

    if (this.numericThousandSeparator != null && this.numericThousandSeparator.length > 0) {
      parts[0] = parts[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, this.numericThousandSeparator);
    }

    if (parts.length > 1 && this.decimalPlaces && parts[1].length > this.decimalPlaces) {
      parts[1] = parts[1].substr(0, this.decimalPlaces);
    }

    result = parts.length > 1
      ? parts.join(this.decimalSeparator || '')
      : parts[0];

    if (this.postfix != null && result.length > 0) {
      result += ' ' + this.postfix;
    }

    return result;
  }

  private checkDecimalMaxPlaces(elem: any): boolean {
    if (this.decimalSeparator == null || this.decimalPlaces == null) {
      return true;
    }

    const index = elem.value?.indexOf(this.decimalSeparator);
    if (index == null || index === -1) {
      return true;
    }

    if (this.getCaretPosition(elem) > index) {
      let value = elem.value || '';
      if (this.postfix != null) {
        value = value.replace(' ' + this.postfix, '');
      }

      const indexFromValue = value.indexOf(this.decimalSeparator);
      return indexFromValue >= (value.length - this.decimalPlaces);
    }

    return true;
  }

  private isInDecimalPart(elem: any): boolean {
    if (this.decimalSeparator == null || this.decimalPlaces == null) {
      return false;
    }

    const index = elem.value?.indexOf(this.decimalSeparator);
    if (index == null || index === -1) {
      return false;
    }

    return this.getCaretPosition(elem) > index;
  }

  private isInPostfixPart(elem: any): boolean {
    if (this.postfix == null) {
      return false;
    }

    const index = elem.value?.indexOf(this.postfix);
    if (index == null || index === -1) {
      return false;
    }

    return this.getCaretPosition(elem) > index;
  }

  // Получить текущую позицию каретки
  private getCaretPosition(elem: any): number {
    return elem.selectionStart || elem.selectionStart === 0
      ? elem.selectionStart
      : 0;
  }

  // Позиция каретки
  private setCaretPosition(elem: any, position: number): void {
    if (elem.createTextRange) {
      const range = elem.createTextRange();
      range.move('character', position);
      range.select();
    } else if (elem.selectionStart) {
      elem.setSelectionRange(position, position);
    }
  }
}
