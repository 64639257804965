<div class="has-validation" [class.form-floating]="label">
  <input #textControl
         [formControl]="inputControl"
         [ngClass]="{
            'is-invalid': control.invalid && control.touched
           }"
         [class.form-control-lg]="!label"
         class="form-control"
         [readOnly]="isReadonly"
         [type]="type"
         autocomplete="off"
         [attr.inputmode]="inputmode"
         [textMask]="{ mask: mask ? mask : false, guide: true, showMask: showMask }"
         [name]="name"
         [placeholder]="placeholder"
         (focus)="focus($event)"
         (blur)="blurControl($event)">
  <label *ngIf="label">{{ label }}</label>
  <app-form-field-error
    *ngIf="control?.invalid && control?.touched"
    [controlErrors]="control.errors"
    [messages]="messages">
  </app-form-field-error>
</div>
