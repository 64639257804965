<div class="card mt-2 mt-sm-4 border-light h-auto shadow-sm rounded-4">
  <div class="card-body p-2 p-sm-4">
    <div class="row card-form-block">
      <div class="col-auto card-form-block-icon">
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="48" height="48" rx="16" fill="#F2F4F7"/>
          <g clip-path="url(#clip0_220_4589)">
            <path
              d="M24 36C21.6266 36 19.3066 35.2962 17.3332 33.9776C15.3598 32.6591 13.8217 30.7849 12.9135 28.5922C12.0052 26.3995 11.7676 23.9867 12.2306 21.6589C12.6936 19.3312 13.8365 17.193 15.5147 15.5147C17.193 13.8365 19.3312 12.6936 21.6589 12.2306C23.9867 11.7676 26.3995 12.0052 28.5922 12.9135C30.7849 13.8217 32.6591 15.3598 33.9776 17.3332C35.2962 19.3066 36 21.6266 36 24C35.9966 27.1815 34.7312 30.2318 32.4815 32.4815C30.2318 34.7312 27.1815 35.9966 24 36ZM24 14C22.0222 14 20.0888 14.5865 18.4443 15.6853C16.7998 16.7841 15.5181 18.3459 14.7612 20.1732C14.0043 22.0004 13.8063 24.0111 14.1922 25.9509C14.578 27.8907 15.5304 29.6725 16.9289 31.0711C18.3275 32.4696 20.1093 33.422 22.0491 33.8079C23.9889 34.1937 25.9996 33.9957 27.8268 33.2388C29.6541 32.4819 31.2159 31.2002 32.3147 29.5557C33.4135 27.9112 34 25.9778 34 24C33.9971 21.3487 32.9426 18.8069 31.0679 16.9322C29.1931 15.0574 26.6513 14.0029 24 14ZM29.666 27.746C29.8641 27.5696 29.9841 27.3218 29.9994 27.057C30.0148 26.7922 29.9244 26.5321 29.748 26.334C29.5716 26.1359 29.3238 26.0159 29.059 26.0006C28.7942 25.9852 28.5341 26.0756 28.336 26.252C27.1135 27.2813 25.5945 27.8936 24 28C22.4064 27.8937 20.8882 27.2821 19.666 26.254C19.4682 26.0774 19.2082 25.9866 18.9434 26.0016C18.6786 26.0166 18.4306 26.1362 18.254 26.334C18.0774 26.5319 17.9866 26.7918 18.0016 27.0566C18.0166 27.3214 18.1362 27.5694 18.334 27.746C19.9219 29.1025 21.9142 29.8951 24 30C26.0858 29.8951 28.0781 29.1025 29.666 27.746ZM18 22C18 23 18.895 23 20 23C21.105 23 22 23 22 22C22 21.4696 21.7893 20.9609 21.4142 20.5858C21.0391 20.2107 20.5304 20 20 20C19.4696 20 18.9609 20.2107 18.5858 20.5858C18.2107 20.9609 18 21.4696 18 22ZM26 22C26 23 26.895 23 28 23C29.105 23 30 23 30 22C30 21.4696 29.7893 20.9609 29.4142 20.5858C29.0391 20.2107 28.5304 20 28 20C27.4696 20 26.9609 20.2107 26.5858 20.5858C26.2107 20.9609 26 21.4696 26 22Z"
              fill="#626F80"/>
          </g>
          <defs>
            <clipPath id="clip0_220_4589">
              <rect width="24" height="24" fill="white" transform="translate(12 12)"/>
            </clipPath>
          </defs>
        </svg>
      </div>
      <div class="col-7 card-form-block-title p-0">
        <div class="fw-bold" [hidden]="formService.isBorrowerFormComplete">Заёмщик</div>
        <div class="text-secondary " [hidden]="formService.isBorrowerFormComplete">Человек, на которого оформлена ипотека</div>
        <div class="fw-bold" [hidden]="!formService.isBorrowerFormComplete">
          <ng-container *ngIf="formBorrower.value?.borrowerFirstName && formBorrower.value?.borrowerLastName">
            {{formBorrower.value?.borrowerFirstName}} {{formBorrower.value?.borrowerMiddleName}} {{formBorrower.value?.borrowerLastName}}
          </ng-container>
          <ng-container *ngIf="!formBorrower.value?.borrowerFirstName && !formBorrower.value?.borrowerLastName">
            Заёмщик
          </ng-container>
        </div>
        <div class="text-secondary " [hidden]="!formService.isBorrowerFormComplete">
          <ng-container *ngIf="formBorrower.value?.borrowerFirstName && formBorrower.value?.borrowerLastName">
            заемщик
          </ng-container>
          <ng-container *ngIf="!formBorrower.value?.borrowerFirstName && !formBorrower.value?.borrowerLastName">
            Человек, на которого оформлена ипотека
          </ng-container>
        </div>
      </div>
      <div class="col" *ngIf="!isBorrowerFormDisabled" [hidden]="!formService.isBorrowerFormComplete">
        <div class="d-flex text-end justify-content-end">
          <button type="button" class="btn btn-outline-primary btn-lg d-none d-sm-block" (click)="formService.isBorrowerFormComplete = !formService.isBorrowerFormComplete">Редактировать</button>
          <button type="button" class="btn btn-outline-light p-2 btn-link d-block d-sm-none" (click)="formService.isBorrowerFormComplete = !formService.isBorrowerFormComplete">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_670_507)">
                <path d="M19.0442 0.956628C18.4776 0.390959 17.7098 0.0732422 16.9092 0.0732422C16.1086 0.0732422 15.3407 0.390959 14.7742 0.956628L1.22085 14.51C0.832722 14.8959 0.524981 15.355 0.315428 15.8606C0.105874 16.3663 -0.00133165 16.9084 1.24844e-05 17.4558V19.1666C1.24844e-05 19.3876 0.0878099 19.5996 0.24409 19.7559C0.40037 19.9122 0.612332 20 0.833346 20H2.54418C3.09148 20.0015 3.63365 19.8945 4.13931 19.6851C4.64496 19.4756 5.10406 19.168 5.49001 18.78L19.0442 5.22579C19.6096 4.65929 19.9271 3.8916 19.9271 3.09121C19.9271 2.29082 19.6096 1.52313 19.0442 0.956628ZM4.31168 17.6016C3.84168 18.0685 3.20665 18.3314 2.54418 18.3333H1.66668V17.4558C1.66584 17.1274 1.73014 16.8021 1.85588 16.4987C1.98161 16.1953 2.16628 15.9198 2.39918 15.6883L12.685 5.40246L14.6017 7.31913L4.31168 17.6016ZM17.865 4.04746L15.7767 6.13663L13.86 4.22413L15.9492 2.13496C16.075 2.00938 16.2244 1.90983 16.3887 1.84197C16.553 1.77411 16.7291 1.73929 16.9069 1.73948C17.0847 1.73967 17.2607 1.77488 17.4249 1.8431C17.5891 1.91131 17.7382 2.01119 17.8638 2.13704C17.9893 2.26289 18.0889 2.41225 18.1568 2.57657C18.2246 2.7409 18.2594 2.91698 18.2592 3.09477C18.2591 3.27255 18.2238 3.44856 18.1556 3.61274C18.0874 3.77692 17.9875 3.92605 17.8617 4.05163L17.865 4.04746Z" fill="#98A2B3"/>
              </g>
              <defs>
                <clipPath id="clip0_670_507">
                  <rect width="20" height="20" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
      </div>
    </div>

    <div [formGroup]="formBorrower" class="row mt-4 borrower" [hidden]="formService.isBorrowerFormComplete">
      <!-- 1 -->
      <div class="col-12 col-md-4 col-xxl-4 mb-3">
        <app-autocomplete
          formControlName="borrowerLastName"
          [suggestionPart]="daDataSystemName.SURNAME"
          [isReadonly]="isLoading"></app-autocomplete>
      </div>
      <!-- 2 -->
      <div class="col-12 col-md-4 col-xxl-4 mb-3">
        <app-autocomplete
          formControlName="borrowerFirstName"
          [suggestionPart]="daDataSystemName.NAME"
          [isReadonly]="isLoading"></app-autocomplete>
      </div>
      <!-- 3 -->
      <div class="col-12 col-md-4 col-xxl-4 mb-3">
        <app-autocomplete
          formControlName="borrowerMiddleName"
          [suggestionPart]="daDataSystemName.PATRONYMIC"
          [isReadonly]="isLoading"></app-autocomplete>
      </div>
      <!-- 4 -->
      <div class="col-12 mb-3">
        <h5>Паспортные данные</h5>
      </div>
      <!-- 5 -->
      <div class="col-12 col-md-4 col-lg-4 mb-4">
        <app-form-text-field formControlName="borrowerPassportLicense" [isReadonly]="isLoading"></app-form-text-field>
      </div>
      <!-- 6 -->
      <div class="col-12 col-md-4 col-lg-4 mb-4">
        <app-form-date-field formControlName="borrowerPassportDate" [isReadonly]="isLoading"></app-form-date-field>
      </div>
      <!-- 7 -->
      <div class="col-12 col-md-4 col-xxl-4 mb-4">
        <app-autocomplete
          formControlName="borrowerDepartmentCode"
          [daDataControlName]="'borrowerPassportIssuerDadata'"
          [suggestionPart]="daDataSystemName.FMS_UNIT"
          (typeaheadOnSelect)="changeBorrowerDepartmentCode($event)"
          [isReadonly]="isLoading"></app-autocomplete>
      </div>

      <!-- 8 -->
      <div class="col-12 col-md-8 mb-4">
        <app-autocomplete
          formControlName="borrowerAddress"
          [daDataControlName]="'borrowerAddressDaData'"
          [suggestionPart]="daDataSystemName.ADDRESS"
          [isReadonly]="isLoading"></app-autocomplete>
      </div>
      <!-- 9 -->
      <div class="col-12 col-md-4 mb-4">
        <app-form-number-field formControlName="borrowerHouseNumber" [isReadonly]="isLoading"></app-form-number-field>
      </div>
      <!-- 10 -->
      <div class="col-12 col-md-12 col-xxl-12 mb-4">
        <app-autocomplete
          formControlName="borrowerPassportIssuer"
          [isTextarea]="true"
          [isResize]="false"
          [daDataControlName]="'borrowerPassportIssuerDadata'"
          [suggestionPart]="daDataSystemName.FMS_UNIT"
          (typeaheadOnSelect)="changeBorrowerPassportIssuer($event)"
          [isReadonly]="isLoading"></app-autocomplete>
      </div>
      <div class="col-12 col-md-4 col-xxl-4 mb-4" *ngIf="formBorrower.get('borrowerBirthPlace')?.enabled">
        <app-form-text-field formControlName="borrowerBirthPlace" [isReadonly]="isLoading"></app-form-text-field>
      </div>
      <div class="col-12 col-md-4 col-xxl-4 mb-4" *ngIf="formBorrower.get('weight')?.enabled">
        <app-form-number-field formControlName="weight" [isReadonly]="isLoading"></app-form-number-field>
      </div>
      <div class="col-12 col-md-4 col-xxl-4 mb-4" *ngIf="formBorrower.get('height')?.enabled">
        <app-form-number-field formControlName="height" [isReadonly]="isLoading"></app-form-number-field>
      </div>
      <div class="col-12 col-md-4 col-xxl-4 mb-4" *ngIf="formBorrower.get('highPressure')?.enabled">
        <app-form-number-field formControlName="highPressure" [isReadonly]="isLoading"></app-form-number-field>
      </div>
      <div class="col-12 col-md-4 col-xxl-4 mb-4" *ngIf="formBorrower.get('lowPressure')?.enabled">
        <app-form-number-field formControlName="lowPressure" [isReadonly]="isLoading"></app-form-number-field>
      </div>
      <!-- 12 -->
      <div class="col-12">
        <div class="row text-end justify-content-end">
          <div class="col text-end" *ngIf="!isApproveOffersComplete">
            <button type="button" class="btn btn-primary btn-lg" (click)="onSubmit(false)">Продолжить</button>
          </div>
          <div class="col-auto text-end" *ngIf="isApproveOffersComplete">
            <button type="button" class="btn btn-outline-secondary btn-lg" (click)="formService.isBorrowerFormComplete = !formService.isBorrowerFormComplete">Отмена</button>
          </div>
          <div class="col-auto text-end" *ngIf="isApproveOffersComplete">
            <button type="button" class="btn btn-primary btn-lg" (click)="onSubmit(true)">Сохранить</button>
          </div>
        </div>
      </div>
<!--      <pre>{{formBorrower.value|json}}</pre>-->
    </div>



  </div>
</div>
