export enum MortgageObjectsEnum {
  Flat = "Квартира",
  Townhouse = "Таунхаус",
  Apartments = "Апартаменты",
  Room = "Комната",
  House = "Жилой дом",
  ParkingPlace = "Машиноместо",
  LandPlot = "Земельный участок",
  HouseWithLandPlot = "Жилой дом на земельном участке",
  IndividualBulding = "Индивидуальное строение",
  NonResidential = "Нежилое помещение",
}
