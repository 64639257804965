import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {Subscription} from "rxjs";
import {ModalService} from "../../services/modal.service";

@Component({
  selector: 'app-pdf-doc-view',
  templateUrl: './pdf-doc-view.component.html',
  styleUrls: ['./pdf-doc-view.component.scss']
})
export class PdfDocViewComponent implements OnInit, OnDestroy {
  public src!: string;

  constructor(public readonly bsModalRef: BsModalRef,
              public readonly modalService: ModalService) {
  }

  // Подписка
  private subscriptions: Subscription[] = [];

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
  }

  // Уничтожение
  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
  // --------------------------------------------------------------------------

}
