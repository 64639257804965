<h3 class="main-page-title mt-3 mb-3">{{ contentService.content.mainPage.title }}</h3>
<div>
  <app-select-company-filter [isLoadingCalculate]="isLoading" (changeFormValue)="getFormValue($event)"></app-select-company-filter>

  <app-progress-circle *ngIf="isLoading" [isLoading]="isLoading" [paymentCountdown]="paymentCountdown"></app-progress-circle>

  <div id="offers-list" class="offers-list" *ngIf="mortgageService.offers.length > 0">
    <app-offer-item *ngFor="let item of mortgageService.offers" [offer]="item"></app-offer-item>
  </div>
</div>

<!--<pre>{{formService.form.value | json}}</pre>-->
