import {environmentProd} from './environment.common.prod';

export const environment = {
  apiKey: '16b80b45f937452981fd8926aa458af3',
  partnerSystemName: 'base',
  partnerBankId: 'lovit',
  // Выбрать другой банк кроме партнерского, нельзя
  onlyPartnerBank: false,
  cacheService: true,
  ...environmentProd,
  yandexMetrikaPartner: 0
};
