import {HttpClient} from '@angular/common/http';
import {ErrorHandler, Inject, Injectable, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {HttpService} from './http.service';
import {AppService} from '../../core/services/app.service';
import {FormService} from './form.service';
import {YandexMetrikaService} from "./yandex-metrika.service";

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandler extends HttpService implements ErrorHandler, OnDestroy {
  private _subscription = new Subscription();

  constructor(
    @Inject(HttpClient) protected override http: HttpClient,
    private readonly appService: AppService,
    private readonly formService: FormService,
    private readonly ym: YandexMetrikaService) {
    super(http);
  }

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  // --------------------------------------------------------------------------

  public handleError(error: Error): void {
    this.ym.onYandexReachGoal('ERROR_GOAL');
    console.error('Error from global error handler', error);
    const message = error.message ?? JSON.stringify(error);
    this.appService.error(message, true);

    this._subscription.add(
      this.postLocal('log', {
        applicationId: this.formService.form?.get('applicationId')?.value,
        message: error.message ?? JSON.stringify(error)
      })
        .subscribe({
          next: () => {
          },
          error: () => {
          }
        })
    );
  }
}
