import {Injectable} from '@angular/core';
import {FormService} from './form.service';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {TextMaskHelper} from '../../shared/helpers/textmaskHelper';
import moment from 'moment-mini';
import {IProperty} from '../interface/property-controls.interface';
import {FormFilterService} from "./form-filter.service";

// Сервис валидации формы
@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor(private readonly formService: FormService,
              private readonly formFilterService: FormFilterService) {
  }

  // Валидация фильтра на главной странице
  public validationFilterFormPage(): boolean {
    return this.formFilterService.form.valid;
  }

  // Применяем валидацию для контролла
  public setControlValidation(propertyControl: IProperty, control: UntypedFormControl): void {
    const validators: ValidatorFn | ValidatorFn[] | null = [];
    if (control && propertyControl && propertyControl?.validation?.values) {
      propertyControl?.validation?.values?.forEach((item) => {
        switch (item.validName) {
          case 'required':
            validators.push(Validators.required);
            break;
          case 'email':
            validators.push(Validators.email);
            break;
          case 'min':
            validators.push(Validators.min(item.validValue || 0));
            break;
          case 'max':
            validators.push(Validators.max(item.validValue || 999));
            break;
          case 'minLength':
            validators.push(Validators.minLength(item.validValue || 0));
            break;
          case 'maxLength':
            validators.push(Validators.maxLength(item.validValue || 999));
            break;
          case 'pattern':
            validators.push(Validators.pattern(new RegExp(item.validValue)));
            break;
          case 'birthDate':
            validators.push(this.birthDate());
            break;
          case 'policyStartDate':
            validators.push(this.policyStartDate());
            break;
          case 'insurerBirthDate':
            validators.push(this.birthDate('insurerPassportDate'));
            break;
          case 'insurerPassportDate':
            validators.push(this.passportDate('insurerBirthDate'));
            break;
          case 'mortgageBeginDate':
            validators.push(this.mortgageBeginDate());
            break;
          case 'mortgageStartDate':
            validators.push(this.mortgageStartDate('mortgageBeginDate'));
            break;
          case 'borrowerDaDataRequired':
            validators.push(this.daDataRequired('borrowerAddress'));
            break;
          case 'borrowerDaDataHouseNumber':
              validators.push(this.daDataAddressHouseFlatNumber('borrowerAddressDaData'));
              break;
          case 'insuranceObjectAddressDaDataHouseNumber':
              validators.push(this.daDataAddressHouseFlatNumber('insuranceObjectAddressDaData'));
              break;
          case 'insurerDaDataRequired':
            validators.push(this.daDataRequired('insurerAddressDaData'));
            break;
          case 'numberValidator':
            validators.push(this.numberValidator('creditRate'));
            break;
          case 'insuranceObjectBuiltYear':
            validators.push(this.insuranceObjectBuiltYearValidator());
            break;
          case 'maxDecimal':
            validators.push(this.maxDecimal());
            break;
          case 'insuranceObjectSquare':
            validators.push(this.insuranceObjectSquare());
            break;
        }
      });
      control.setValidators(validators);
    }

    return;
  }

  // Валидация даты рождения
  public birthDate(dependedControlName?: string): ValidatorFn {
    return (fc: AbstractControl) => {
      const value = TextMaskHelper.getDate(fc.value);

      const date = moment(value || '', 'DD.MM.YYYY', true);
      if (!date.isValid() || date.isAfter()) {
        return {
          customValidation: true,
          message: 'Укажите корректную дату рождения'
        };
      }

      if (moment(value || '', 'DD.MM.YYYY', true).add(18, 'years').isAfter()) {
        return {
          customValidation: true,
          message: 'Должно быть больше 18 лет'
        };
      }

      if (!moment(value || '', 'DD.MM.YYYY', true).add(70, 'years').isAfter()) {
        return {
          customValidation: true,
          message: 'Заёмщику должно быть не более 70 лет'
        };
      }

      const experienceDate = (fc.parent as UntypedFormGroup)?.get('experienceDate');
      if (experienceDate && !experienceDate.valid) {
        experienceDate?.updateValueAndValidity();
        return null;
      }
      return null;
    };
  }

  // Валидация даты начала стажа
  public experienceStartDate(ignore = () => false, birthDateFieldName: string = 'driverBirthDate'): ValidatorFn {
    return (fc: AbstractControl) => {
      const value = TextMaskHelper.getDate(fc.value);
      if (value == null) {
        return {
          customValidation: true,
          message: 'Укажите корректную дату'
        };
      }

      const date = moment(value, 'DD.MM.YYYY', true);
      if (!date.isValid() || date.isAfter()) {
        return {
          customValidation: true,
          message: 'Укажите корректную дату'
        };
      }

      const birthDate = (fc.parent as UntypedFormGroup)?.get(birthDateFieldName);
      if (birthDate && !birthDate.valid) {
        birthDate?.updateValueAndValidity();
        return null;
      }

      const birthDateValue = birthDate ? TextMaskHelper.getDate(birthDate.value) : null;
      if (birthDateValue && moment(birthDateValue, 'DD.MM.YYYY', true).add(16, 'year').isAfter(date)) {
        return {
          customValidation: true,
          message: 'Стаж начался в возрасте до 16 лет'
        };
      }

      return null;
    };
  }

  public experienceStartYear(ignore = () => false, birthDateFieldName: string = 'driverBirthDate'): ValidatorFn {
    return (fc: AbstractControl) => {

      const value = TextMaskHelper.getYear(fc.value);
      if (value == null || moment().year() < Number(value)) {
        return {
          customValidation: true,
          message: 'Укажите корректный год'
        };
      }

      const date = moment('31.12.' + value, 'DD.MM.YYYY', true);
      if (!date.isValid()) {
        return {
          customValidation: true,
          message: 'Укажите корректный год'
        };
      }

      const birthDate = (fc.parent as UntypedFormGroup)?.get(birthDateFieldName);
      if (!birthDate?.valid) {
        return null;
      }

      const birthDateValue = TextMaskHelper.getDate(birthDate.value);
      if (moment(birthDateValue || '', 'DD.MM.YYYY', true).add(16, 'year').isAfter(date)) {
        return {
          customValidation: true,
          message: 'Стаж начался в возрасте до 16 лет'
        };
      }

      return null;
    };
  }

  // Валидация контролла год выпуска авто
  public productionYear(ignore = () => false): ValidatorFn {
    return (fc: AbstractControl) => {
      const stsDate = (fc.parent as UntypedFormGroup)?.get('stsDate');
      stsDate?.updateValueAndValidity();
      const ptsDate = (fc.parent as UntypedFormGroup)?.get('ptsDate');
      ptsDate?.updateValueAndValidity();
      const ePtsDate = (fc.parent as UntypedFormGroup)?.get('ePtsDate');
      ePtsDate?.updateValueAndValidity();
      return null;
    };
  }

  // Валидация даты выдачи паспорта
  public passportDate(birthdateFieldName: string, ignore = () => false): ValidatorFn {
    return (fc: AbstractControl) => {
      if (ignore()) {
        return null;
      }

      const value = TextMaskHelper.getDate(fc.value);
      if (value == null) {
        return {
          customValidation: true,
          message: 'Укажите корректную дату выдачи паспорта'
        };
      }

      const date = moment(value, 'DD.MM.YYYY', true);
      if (!date.isValid() || date.isAfter()) {
        return {
          customValidation: true,
          message: 'Укажите корректную дату выдачи паспорта'
        };
      }

      if (!moment(value || '', 'DD.MM.YYYY', true).add(100, 'years').isAfter()) {
        return {
          customValidation: true,
          message: 'Укажите корректную дату выдачи паспорта'
        };
      }

      const birthDate = (fc.parent as UntypedFormGroup)?.get(birthdateFieldName);

      if (birthDate && !birthDate.valid) {
        birthDate?.updateValueAndValidity();
        return null;
      }

      return null;
    };
  }

  // Валидация даты начала стажа
  public policyStartDate(ignore = () => false): ValidatorFn {
    return (fc: AbstractControl) => {
      const value = TextMaskHelper.getDate(fc.value);
      if (value == null) {
        return {
          customValidation: true,
          message: 'Укажите корректную дату'
        };
      }
      const minDate = moment();

      const date = moment(value, 'DD.MM.YYYY', true);
      if (!date.isValid() || date.isBefore(minDate)) {
        return {
          customValidation: true,
          message: 'Дата должна быть больше текущей'
        };
      }

      if (!moment(value, 'DD.MM.YYYY', true).isBefore(moment().add(30, 'days'))) {
        return {
          customValidation: true,
          message: 'Дата начала договора не может быть более чем через 30 дней'
        };
      }

      return null;
    };
  }

  // Валидация даты начала договора
  public mortgageBeginDate(ignore = () => false): ValidatorFn {
    return (fc: AbstractControl) => {
      const value = TextMaskHelper.getDate(fc.value);
      if (value == null) {
        return {
          customValidation: true,
          message: 'Укажите корректную дату'
        };
      }
      const maxDate = moment();

      const date = moment(value, 'DD.MM.YYYY', true);
      if (!date.isValid() || date.isAfter(maxDate)) {
        return {
          customValidation: true,
          message: 'Дата не может быть позже текущего дня'
        };
      }

      const thirtyYearsAgo = moment().subtract(35, 'years');

      if (date.isBefore(thirtyYearsAgo)) {
        return {
          customValidation: true,
          message: 'Дата не может быть раньше 35 лет от текущего дня'
        };
      }

      return null;
    };
  }

  // Валидация даты начала страхования
  public mortgageStartDate(mortgageBeginDateControlName: string, ignore = () => false): ValidatorFn {
    return (fc: AbstractControl) => {
      const value = TextMaskHelper.getDate(fc.value);
      if (value == null) {
        return {
          customValidation: true,
          message: 'Укажите корректную дату'
        };
      }
      const minDate = moment();

      const date = moment(value, 'DD.MM.YYYY', true);
      if (!date.isValid() || date.isBefore(minDate)) {
        return {
          customValidation: true,
          message: 'Дата должна быть больше текущей'
        };
      }

      if (!moment(value, 'DD.MM.YYYY', true).isBefore(moment().add(90, 'days'))) {
        return {
          customValidation: true,
          message: 'Дата начала договора не может быть более чем через 90 дней'
        };
      }

      const mortgageBeginDateControl = (fc.parent as UntypedFormGroup)?.get(mortgageBeginDateControlName);
      console.log(mortgageBeginDateControl);


      if (mortgageBeginDateControl && !mortgageBeginDateControl.valid) {
        mortgageBeginDateControl?.updateValueAndValidity();
        return null;
      }

      return null;
    };
  }

  // Обязательный выбор из списка, полученного с сервиса daData
  public daDataRequired(daDataFieldName: string): ValidatorFn {
    return (fc: AbstractControl) => {
      const cityDaData = (fc.parent as UntypedFormGroup)?.get(daDataFieldName)?.value;

      // if (!(Number(cityDaData?.fias_level) >= 8 || (Number(cityDaData?.fias_level) === 7 && cityDaData?.house != null))) {
      //   return {
      //     customValidation: true,
      //     message: 'Укажите адрес до дома'
      //   };
      // }

      return null;
    };
  }

  // Валидация номера квартиры в адресе
  public daDataAddressHouseFlatNumber(daDataFieldName: string): ValidatorFn {
    return (fc: AbstractControl) => {
      const cityDaData = (fc.parent as UntypedFormGroup)?.get(daDataFieldName)?.value;

      // Если нету номера дома
      if (cityDaData && !cityDaData?.house) {
        return {
          customValidation: true,
          message: 'Укажите полный адрес до дома'
        };
      }

      return null;
    };
  }

  // Вадлидация смс кода
  public errorCode(isValidCode: boolean): ValidatorFn {
    return (fc: AbstractControl) => {

      if (!isValidCode) {
        return {
          customValidation: true,
          message: 'Вы ввели неверный смс код'
        };
      }

      return null;
    };
  }

  // Валидация целых и дробных чисел
  public numberValidator(fieldName: string, ignore = () => false): ValidatorFn {
    return (fc: AbstractControl) => {
      if (ignore()) {
        return null;
      }

      const control = (fc.parent as UntypedFormGroup)?.get(fieldName);

      const isNumber = /^\d+(\.\d{1,2})?$/.test(control?.value);
      return isNumber ? null : { invalidNumber: true };
    };
  }

  // Валидация год постройки дома
  public insuranceObjectBuiltYearValidator(ignore = () => false): ValidatorFn {
    return (fc: AbstractControl) => {
      if (ignore()) {
        return null;
      }

      const currentYear = new Date().getFullYear();


      if (Number(fc.value) > currentYear) {
        return {
          customValidation: true,
          message: 'Год постройки не может быть больше текущего'
        };
      }

      return null;
    };
  }

  // Валидация годовая ставка
  public maxDecimal(ignore = () => false): ValidatorFn {
    return (fc: AbstractControl) => {
      if (ignore()) {
        return null;
      }

      const stringNumber = fc?.value?.toString().replace(',', '.');

      if (stringNumber > 20) {
        return {
          customValidation: true,
          message: 'Максимальная ставка 20%'
        };
      }

      return null;
    };
  }

  // Валидация площадь квартиры
  public insuranceObjectSquare(ignore = () => false): ValidatorFn {
    return (fc: AbstractControl) => {
      if (ignore()) {
        return null;
      }

      return null;
    };
  }

}
