import {Injectable} from '@angular/core';
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {Subscription} from 'rxjs';

// Хелперы

// Перечисления
import {MortgageObjectsEnum} from "../enums/mortgage-objects.enum";

// Интерфейсы
import {IMortgageObjectItem} from "../interface/mortgage-application.interface";
import {IBanksAndProduct} from "../interface/mortgage-products.interface";

@Injectable({
  providedIn: 'root'
})
export class FormOfflineService {

  constructor(private readonly fb: UntypedFormBuilder) {
     this.initMortgageObjectsList();
  }

  // Форма
  public form: UntypedFormGroup = this.fb.group({
    offlineFormFirstName: new FormControl('', Validators.required),
    offlineFormLastName: new FormControl('', Validators.required),
    offlineFormMiddleName: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    phone: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
  });
  // Список объектов страхования ипотеки
  public mortgageObjects!: IMortgageObjectItem[];
  // Список всех продуктов
  public allMortgageProductList: IBanksAndProduct[] = [];

  public validateAllFormFields(formGroup: UntypedFormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsUntouched({onlySelf: true});
        control.markAsPristine();
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  // Инициализация объектов страхования ипотеки
  private initMortgageObjectsList(): void {
    this.mortgageObjects = Object.entries(MortgageObjectsEnum).map(([key, value]) => ({
      id: key,
      value: value,
    }));
  }

  // Уникальные значения для списка доступных продуктов
  public getUniqueInsurerTypeBankProducts(bankProducts: IBanksAndProduct[]): IBanksAndProduct[] {
    const uniqueInsurerTypes: string[] = [];

    // Фильтруем массив банковских продуктов, чтобы оставить только уникальные InsurerType
    const uniqueBankProducts: IBanksAndProduct[] = bankProducts.filter((product) => {
      if (uniqueInsurerTypes.includes(product.bank)) {
        return false;
      } else {
        uniqueInsurerTypes.push(product.bank);
        return true;
      }
    });

    return uniqueBankProducts;
  }

}
