<div class="select-company-filter card border-0 rounded-5" [formGroup]="filterForm">
  <div class="card-body p-sm-4 d-flex flex-column">
    <div class="block-spinner" *ngIf="isLoading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <app-select-object></app-select-object>
    <div class="morgageMainFilter row pb-0 pb-md-4" *ngIf="!isLoading">
      <div *ngIf="environment.partnerSystemName === 'ozon'" class="col-12 col-lg-6 col-xl-3 mb-3 mb-xl-0">
        <app-autocomplete
          formControlName="mortgagePropertyType"
          [typeaheadList]="formFilterService.mortgageObjects"
          [typeaheadOptionsLimit]="formFilterService.mortgageObjects.length"
          [notReset]="true"
          [isDropdown]="true"></app-autocomplete>
      </div>
      <div class="col-12 col-lg-6 col-xl-3 mb-3 mb-xl-0">
        <app-autocomplete
          formControlName="bankId"
          [isReadonly]="isLoading || onlyPartnerBank"
          [typeaheadList]="mortgageProductList"
          [typeaheadOptionField]="'bank'"
          [idKeyName]="'bankId'"
          [typeaheadOptionsLimit]="mortgageProductList.length"
          (typeaheadOnSelect)="selectBank($event)"
          [isDropdown]="false"></app-autocomplete>
      </div>
      <div class="col-12 col-lg-6 col-xl-3 mb-3 mb-xl-0">
        <app-form-number-field formControlName="creditRemain" [isReadonly]="isLoading"></app-form-number-field>
      </div>
      <div class="col-12 col-lg-4 col-xl-2 mb-3 mb-xl-0">
        <app-form-date-field formControlName="insuredBirthDate" [isReadonly]="isLoading"></app-form-date-field>
      </div>
      <div class="col-12 col-lg-4 col-xl-2 mb-3 mb-xl-0">
        <app-autocomplete
          formControlName="insuredGender"
          [typeaheadList]="formFilterService.genderList"
          [typeaheadOptionsLimit]="2"
          [notReset]="true"
          [isDropdown]="true"></app-autocomplete>
      </div>
      <div class="col-12 col-lg-4 col-xl-2 mx-auto">
        <button [disabled]="isLoadingCalculate" type="button" class="btn btn-primary calculate-btn btn-lg w-100" (click)="calculate()">
          <span *ngIf="isLoadingCalculate" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
          <span *ngIf="!isLoadingCalculate">Рассчитать</span>
        </button>
      </div>
    </div>

    <div *ngIf="bankSystemName === 'sber'">
      <div class="alert alert-primary m-0 mt-sm-0 mt-3 mb-2 mb-sm-0" role="alert" *ngIf="formFilterService.form.get('product')?.value?.toLowerCase() === 'property'">
        Посмотрите <a href="javascript:void(0);" (click)="showPdfDocModal('./assets/landingFiles/tt_insur.pdf')">требования</a> страхования имущества
      </div>

      <div class="alert alert-primary m-0 mt-sm-0 mt-3 mb-2 mb-sm-0" role="alert" *ngIf="formFilterService.form.get('product')?.value?.toLowerCase() === 'life'">
        Посмотрите <a href="javascript:void(0);" (click)="showPdfDocModal('./assets/landingFiles/pril_5_min_tr.pdf')">требования</a> по страхованию жизни и здоровья
      </div>

      <div class="alert alert-primary m-0 mt-sm-0 mt-3 mb-2 mb-sm-0" role="alert" *ngIf="formFilterService.form.get('product')?.value?.toLowerCase() === 'lifeandproperty'">
        Посмотрите требования по страхованию <a href="javascript:void(0);" (click)="showPdfDocModal('./assets/landingFiles/tt_insur.pdf')">имущества</a>, <a href="javascript:void(0);" (click)="showPdfDocModal('./assets/landingFiles/pril_5_min_tr.pdf')">жизни и здоровья</a>
      </div>
    </div>

    <div *ngIf="bankSystemName === 'bcs'">
      <div class="alert alert-primary m-0 mt-sm-0 mt-3 mb-2 mb-sm-0" role="alert" *ngIf="formFilterService.form.get('product')?.value?.toLowerCase() === 'property'">
        Посмотрите <a href="javascript:void(0);" (click)="showPdfDocModal('./assets/landingFiles/tt_insur.pdf')">требования</a> страхования имущества
      </div>

      <div class="alert alert-primary m-0 mt-sm-0 mt-3 mb-2 mb-sm-0" role="alert" *ngIf="formFilterService.form.get('product')?.value?.toLowerCase() === 'life'">
        Посмотрите <a href="javascript:void(0);" (click)="showPdfDocModal('./assets/landingFiles/pril_5_min_tr.pdf')">требования</a> по страхованию жизни и здоровья
      </div>
    </div>

    <div class="alertList m-0 mt-3 mt-md-0" *ngIf="bankSystemName !== 'sber' && bankSystemName !== 'bcs'">
      <div class="alert alert-primary mb-0" role="alert" *ngIf="formFilterService.form.get('product')?.value?.toLowerCase() === 'property'">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 21C17.5842 21 21 17.5842 21 12C21 6.4158 17.5842 3 12 3C6.4158 3 3 6.4158 3 12C3 17.5842 6.4158 21 12 21ZM13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44771 11.4477 7 12 7C12.5523 7 13 7.44771 13 8ZM11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V12Z" fill="#001A34" fill-opacity="0.4"/>
        </svg>
        Полис «Имущество» нужен, если дом сдан, и вы получили право собственности. Это требование закона
      </div>

      <div class="alert alert-primary mb-0" role="alert" *ngIf="formFilterService.form.get('product')?.value?.toLowerCase() === 'life'">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 21C17.5842 21 21 17.5842 21 12C21 6.4158 17.5842 3 12 3C6.4158 3 3 6.4158 3 12C3 17.5842 6.4158 21 12 21ZM13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44771 11.4477 7 12 7C12.5523 7 13 7.44771 13 8ZM11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V12Z" fill="#001A34" fill-opacity="0.4"/>
        </svg>
        Полис «Жизнь и здоровье» нужен, если дом не построен и нет права собственности, и для снижения ставки по кредиту
      </div>

      <div class="alert alert-primary mb-0" role="alert" *ngIf="formFilterService.form.get('product')?.value?.toLowerCase() === 'lifeandproperty'">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 21C17.5842 21 21 17.5842 21 12C21 6.4158 17.5842 3 12 3C6.4158 3 3 6.4158 3 12C3 17.5842 6.4158 21 12 21ZM13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44771 11.4477 7 12 7C12.5523 7 13 7.44771 13 8ZM11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V12Z" fill="#001A34" fill-opacity="0.4"/>
        </svg>
        Полис «Имущество+жизнь и здоровье» позволяет снизить ставку по кредиту и выполнить требование закона об ипотеке
      </div>

      <div class="alert alert-primary mb-0" role="alert" *ngIf="formFilterService.form.get('product')?.value?.toLowerCase() === 'titleandproperty'">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 21C17.5842 21 21 17.5842 21 12C21 6.4158 17.5842 3 12 3C6.4158 3 3 6.4158 3 12C3 17.5842 6.4158 21 12 21ZM13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44771 11.4477 7 12 7C12.5523 7 13 7.44771 13 8ZM11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V12Z" fill="#001A34" fill-opacity="0.4"/>
        </svg>
        Титульное страхование защищает от потери права собственности.
      </div>
    </div>

  </div>

</div>
