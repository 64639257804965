import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

// Перечисления
import { ROUTES } from '../../shared/enums/routes.enum';

// Сервисы
import { ValidationService } from '../../shared/services/validation.service';
import { MortgageService } from '../../shared/services/mortgage.service';

@Injectable({
    providedIn: 'root'
})
export class PermitRouteService {

    constructor(private readonly validationService: ValidationService) {

    }

    public isPermitRoute(url: string): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let isPermit = false;
        switch (url) {
            case(ROUTES.MainPage): {
                isPermit = true;
                break;
            }
            case(ROUTES.Form): {
                isPermit = this.validationService.validationFilterFormPage();
                break;
            }

        }

        return isPermit;
    }
}
