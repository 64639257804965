import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MainComponent} from './components/main/main.component';
import {MortgageRoutingModule} from "./mortgage-routing.module";
import {SharedModule} from "../../shared/shared.module";
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import {SelectCompanyFilterComponent} from './components/main/select-company-filter/select-company-filter.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ContactFormComponent} from './components/contact-form/contact-form.component';
import {BorrowerFormComponent} from "./components/borrower-form/borrower-form.component";
import {MortgageFormComponent} from "./components/mortgage-form/mortgage-form.component";
import {PlicyDateFormComponent} from "./components/policy-date-form/plicy-date-form.component";
import {InsuranceObjectFormComponent} from "./components/insurance-object-form/insurance-object-form.component";
import { SelectObjectComponent } from './components/main/select-company-filter/components/select-object/select-object.component';
import { FormComponent } from './components/form/form.component';
import { OfflineFormComponent } from './components/offline-form/offline-form.component';
import {AlertModule} from "ngx-bootstrap/alert";
import { SuccessPageComponent } from './components/success-page/success-page.component';
import {PopoverModule} from "ngx-bootstrap/popover";
import {TooltipModule} from "ngx-bootstrap/tooltip";


@NgModule({
  declarations: [
    MainComponent,
    SelectCompanyFilterComponent,
    ContactFormComponent,
    BorrowerFormComponent,
    MortgageFormComponent,
    PlicyDateFormComponent,
    InsuranceObjectFormComponent,
    SelectObjectComponent,
    FormComponent,
    OfflineFormComponent,
    SuccessPageComponent,
  ],
    imports: [
        CommonModule,
        SharedModule,
        MortgageRoutingModule,
        BsDatepickerModule,
        ReactiveFormsModule,
        AlertModule,
        PopoverModule,
        FormsModule,
        TooltipModule,
    ]
})
export class MortgageModule {
}
