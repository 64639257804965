import {Component, OnDestroy} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {Subscription} from "rxjs";

// Перечисления
import {DaData} from "../../../../shared/enums/da-data.enum";

// Сервисы
import {FormOfflineService} from "../../../../shared/services/form-offline.service";
import {MortgageService} from "../../../../shared/services/mortgage.service";
import {finalize} from "rxjs/operators";
import {NavigationService} from "../../../../shared/services/navigation.service";

@Component({
  selector: 'app-offline-form',
  templateUrl: './offline-form.component.html',
  styleUrls: ['./offline-form.component.scss']
})
export class OfflineFormComponent implements OnDestroy {
  constructor(public bsModalRef: BsModalRef,
              private readonly formOfflineService: FormOfflineService,
              private readonly mortgageService: MortgageService,
              private readonly navigationService: NavigationService) {}

  // Индикатор загрузки
  public isLoading = false;
  // DaData системные имена
  public daDataSystemName = DaData;
  // Подписка
  private subscriptions: Subscription[] = [];
  // Форма
  public formOffline = this.formOfflineService.form;

  // --------------------------------------------------------------------------
  // Инициализация
  // Уничтожение
  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
  // --------------------------------------------------------------------------

  // Отправялем данные формы оффлайн анкеты
  public onSubmit(): void {
    this.formOffline.markAllAsTouched();
    if (this.formOffline.invalid) return;
    this.isLoading = true;

    this.subscriptions.push(
      this.mortgageService.saveOfflineMortgage(this.formOffline.value)
        .pipe(
          finalize(() => this.isLoading = false)
        )
        .subscribe((res) => {
          if (res.result) {
            this.bsModalRef.hide();
            this.navigationService.navigate('/success', { status: 'offline' });
          }
        })
    )

  }

}
